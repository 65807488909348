import React,{Component} from 'react'
import '../App.css';
import Header from "../components/Header";
import volImgTest from "../imgs/vol.png"
import donImgTest from "../imgs/donate.png"
import missionIcon from "../imgs/missionIcon.png"
import visionIcon from "../imgs/visionIcon.png"
import  handsImage from '../imgs/handsImage.png'
import {Link} from "react-router-dom";
import HomeNewsBanner from "../components/HomeNewsBanner";
import HomeRoundedTabs from "../components/HomeRoundedTabs";
import Footer from "../components/Footer";
import ServerController from "../utilities/ServerController";
import Translator from "../utilities/Translator";
import HomeSlider from "../components/HomeSlider";
import { ParallaxProvider ,Parallax} from 'react-scroll-parallax';
import CountUp from "react-countup";
import animation from "../seeds_loader";
import Lottie from "lottie-react-web";

export default class Home extends Component {
    constructor() {
        super();
        this.state = {loading:false,showFooter:false,isBottom:false,isMobile:false,donateImg:'',volImg:'',donAndVolText:'',missionTitle:'',missionText:'',visionTitle:'',visionText:'',volunteersNum:0,countriesNum:0,hoursNum:0,lang:localStorage.getItem('lang'),hideNews:false};
    }
    componentWillMount() {
        if(window.innerWidth<1090){
            this.setState({isMobile:true})
        }else{
            this.setState({isMobile:false})
        }
    }

    componentDidMount(){
        this.setState({loading:true})
        this.timer = setInterval(() => {
                    this.setState({
                        loading:false
                });

        }, 1000);
        if(window.innerWidth<1090){
            this.setState({isMobile:true})
        }else{
            this.setState({isMobile:false})
        }

        let controller=new ServerController();
        controller.getHomePageEelements(this.handleData,this,this.state.lang);

    }
    isBottom(el) {
        if(el ==null)
            return  false;
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }
    isShowFooter(el){
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }
    trackScrolling = (e) => {
        const wrappedElement = document.getElementById('HomeRoundedTabsDiv');
        if (this.isBottom(wrappedElement)) {
            this.setState({isBottom:true});
            document.removeEventListener('scroll', this.trackScrolling);
        }
        const footer = document.getElementById('factsDiv');
        if (this.isBottom(footer)) {
            this.setState({showFooter:true});
        }else{
            this.setState({showFooter:false});
        }
    };
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
        window.removeEventListener("resize", this.resize.bind(this));
    }
    resize() {
        if(window.innerWidth<1090){
            this.setState({isMobile:true})
        }else{
            this.setState({isMobile:false})
        }
    }
    handleData(data,context){
        let donateImg,volImg,donAndVolText,missionTitle,missionText,visionTitle,visionText,volunteersNum,countriesNum,hoursNum;
        if(data==null)
            return
        for(let i=0;i<data.length;i++) {
            switch (data[i].name) {
                case "donateImage":
                    donateImg=data[i].content;
                    break;
                case "volImg":
                    volImg=data[i].content;
                    break;
                case "donAndVolText":
                    donAndVolText=data[i].content;
                    break;
                case "missionTitle":
                    missionTitle=data[i].content;
                    break;
                case "missionText":
                    missionText=data[i].content;
                    break;
                case "visionTitle":
                    visionTitle=data[i].content;
                    break;
                case "visionText":
                    visionText=data[i].content;
                    break;
                case "volunteersNum":
                    volunteersNum=data[i].content;
                    break;
                case "countriesNum":
                    countriesNum=data[i].content;
                    break;
                case "hoursNum":
                    hoursNum=data[i].content;
                    break;

            }
        }
        context.setState({donateImg:donateImg,volImg:volImg,donAndVolText:donAndVolText,missionTitle:missionTitle,missionText:missionText,visionTitle:visionTitle,visionText:visionText
            ,volunteersNum:volunteersNum,countriesNum:countriesNum,hoursNum:hoursNum})
    }
    hideNewsCallBack(hide,context){
        context.setState({hideNews:true})

    }
    getFactYear(){
        const oneDay = 24 * 60 * 60 * 1000;
        let nowDate=new Date();
        let seedsDate=new Date(2015,2,1);
        const diffDays = Math.round(Math.abs((nowDate - seedsDate) / oneDay));
        return Math.round(diffDays/365);
    }
    renderWeb() {
        return (
            <div dir={this.state.lang=="ar"?"rtl":"ltr"}>
                <Header page={'home'}  context={this}></Header>
                <div id={'homePage'}>
                <ParallaxProvider>


                    <div id="HomePageContent" >
                        {/*<HomeSlider/>*/}
                        <div style={{marginBottom:150}}/>
                        <img src={require('../imgs/split.png')} style={{width:'100%',height:5,display:'flex',marginBottom:0}} />

                        <div className={'row volAndDivSection'}>
                            <div  className={'col-6 donateSection'}>
                                <Link to="/donate"><button style={this.state.lang=='ar'?{fontSize:this.state.arSize,paddingLeft:'25px',paddingRight:'25px'}:{}}id='donateBtn'>{Translator.getString('DONATE',this.state.lang)}</button></Link>
                            </div>
                            <div  className={'col-6 volSection'}>
                                <Link to="/volunteer"><button  style={this.state.lang=='ar'?{fontSize:this.state.arSize,paddingLeft:'25px',paddingRight:'25px'}:{}} id='volBtn'>{Translator.getString('VOLUNTEER',this.state.lang)}</button></Link>
                            </div>
                        </div>
                        <img src={require('../imgs/split.png')} style={{width:'100%',height:5,display:'flex',marginTop:0,backgroundColor:'red',margin:0,padding:0}} />
                        <div id='homeSubPageContent'>



                            {/*<div style={{width:'100%'}}>
                                <div style={this.state.hideNews==false?{width: '45%',margin: 'auto'}:{display:'none'}}>
                                    <HomeNewsBanner hideCallBack={this.hideNewsCallBack} context={this}/>
                                </div>
                            </div>*/}
                            <div  style={{height:'300px',position:'relative'}} >
                                    <Parallax y={[-10, 10]} tagOuter="figure">
                                        <img src={require('../imgs/coverback.png')} style={{width:'100%',height:'100%',margin:0,objectFit:'fill',padding:0,zIndex:'-1',opacity:'0.2'}} />
                                    </Parallax>
                                    <div className={" missionVisionSection"}>
                                        <div id='donAndVolText' className='ql-editor'
                                             dangerouslySetInnerHTML={{__html: this.state.donAndVolText}}></div>
                                        <div className={'row'} style={{width:'100%',margin:'auto'}}>
                                            <div className={' visionBack col-6'}>
                                                <div id='missionDiv'>
                                                    <div style={{width: '100%', display: 'table'}}>
                                                        <div className='ql-editor' style={{
                                                            display: 'inline-block',
                                                            verticalAlign: 'middle',
                                                            textAlign:'center',
                                                            width: '100%'
                                                        }} dangerouslySetInnerHTML={{__html: this.state.visionTitle}}></div>
                                                    </div>
                                                    <div className='ql-editor' style={{height: '40%', width: '100%',textAlign:'center',}}
                                                         dangerouslySetInnerHTML={{__html: this.state.visionText}}></div>
                                                </div>
                                            </div>
                                            <div className={'col-6 missionBack'}>
                                                <div id='missionDiv' style={{paddingTop:20}}>
                                                    <div style={{width: '100%', display: 'table'}}>
                                                        <div className='ql-editor' style={{
                                                            display: 'inline-block',
                                                            verticalAlign: 'middle',
                                                            textAlign:'center',
                                                            width: '100%'
                                                        }} dangerouslySetInnerHTML={{__html: this.state.missionTitle}}></div>
                                                    </div>
                                                    <div className='ql-editor' style={{height: '40%', width: '100%',textAlign:'center',}}
                                                         dangerouslySetInnerHTML={{__html: this.state.missionText}}></div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                            </div>

                            <img src={require('../imgs/split.png')} style={{width:'100%',height:5,display:'flex',marginTop:0,margin:0,padding:0}} />
                            <Parallax className={'whereWeWorkImageSection'} y={[0, '-15%']} tagOuter="figure" >
                                <div  className={this.state.lang=='ar'?'whereWeWorkImageSectionBackAr':'whereWeWorkImageSectionBack'}  style={{width:'100%',height:470}}/>
                                <div className={this.state.lang=='ar'?'whereWeWorkImageContentAr':'whereWeWorkImageContent'}>
                                    {this.state.lang=='ar'?<div dir={'rtl'}>
                                            <p>تعمل بذور من أجل الانسانية في</p>
                                            <p> 10 دول حول العالم</p>
                                            <Link to="/wherewework"><button><p className={'whereWeWorkButton'}>اكتشف المزيد</p></button></Link>
                                        </div>
                                        :<div>
                                        <p>Seeds of Humanity operates in</p>
                                        <p>10 countries around the world</p>
                                            <Link to="/wherewework"><button><p className={'whereWeWorkButton'}>Discover more</p></button></Link>
                                    </div>}
                                </div>
                            </Parallax>
                            <img src={require('../imgs/split.png')} style={{width:'100%',height:5,display:'flex',marginTop:0,margin:0,padding:0}} />

                            <HomeRoundedTabs lang={this.state.lang}/>
                            <img src={require('../imgs/split.png')} style={{width:'100%',height:5,display:'flex',marginTop:40,padding:0}} />
                            <div id='factsDiv'>
                                <h2>{Translator.getString("Fun facts about Seeds",this.state.lang)}</h2>
                                <div id='factItems' class='row'>
                                    <div class='factItem col-xl-3 col-lg-3 col-sm-6 col-6'>
                                        {this.state.isBottom?<div style={{width:'100%',display:'flex',justifyContent:'center',alignSelf:'center'}}>
                                            <CountUp
                                                start={0}
                                                end={this.state.volunteersNum}
                                                duration={3}
                                                separator=" "
                                                className={'factItemNumber'}
                                                decimal=","

                                                onEnd={() => console.log('Ended! 👏')}
                                                onStart={() => console.log('Started! 💨')}
                                            />
                                        </div>:<h3>{this.state.volunteersNum}</h3>}
                                        <p>{Translator.getString("Volunteers",this.state.lang)}</p>
                                    </div>
                                    <div class='factItem col-xl-3 col-lg-3 col-sm-6 col-6'>

                                        {this.state.isBottom?<div style={{width:'100%',display:'flex',justifyContent:'center',alignSelf:'center'}}>
                                            <CountUp
                                                start={0}
                                                end={this.state.countriesNum}
                                                duration={3}
                                                separator=" "
                                                className={'factItemNumber'}
                                                decimal=","

                                                onEnd={() => console.log('Ended! 👏')}
                                                onStart={() => console.log('Started! 💨')}
                                            />
                                        </div>:<h3>{this.state.countriesNum}</h3>}
                                        <p>{Translator.getString("Countries they come from",this.state.lang)}</p>
                                    </div>
                                    <div class='factItem col-xl-3 col-lg-3 col-sm-6 col-6'>

                                        {this.state.isBottom?<div style={{width:'100%',display:'flex',justifyContent:'center',alignSelf:'center'}}>
                                            <CountUp
                                                start={0}
                                                end={this.state.hoursNum}
                                                duration={3}
                                                separator=" "
                                                className={'factItemNumber'}
                                                decimal=","

                                                onEnd={() => console.log('Ended! 👏')}
                                                onStart={() => console.log('Started! 💨')}
                                            />
                                        </div>:<h3>{this.state.hoursNum}</h3>}
                                        <p>{Translator.getString("Hours of volunteering",this.state.lang)}</p>
                                    </div>
                                    <div class='factItem col-xl-3 col-lg-3 col-sm-6 col-6'>
                                        {this.state.isBottom?<div style={{width:'100%',display:'flex',justifyContent:'center',alignSelf:'center'}}>
                                            <CountUp
                                                start={0}
                                                end={this.getFactYear()}
                                                duration={3}
                                                separator=" "
                                                className={'factItemNumber'}
                                                decimal=","

                                                onEnd={() => console.log('Ended! 👏')}
                                                onStart={() => console.log('Started! 💨')}
                                            />
                                        </div>:<h3>{this.getFactYear()}</h3>}
                                        <p>{Translator.getString("Years",this.state.lang)}</p>

                                    </div>
                                </div>
                            </div>
                            <img src={require('../imgs/split.png')} style={{width:'100%',height:5,display:'flex',marginTop:0,padding:0}} />
                        </div>

                    </div>

                </ParallaxProvider>

                <div style={{width:'100%',height:300,position:'relative'}}>
                    <Footer lang={this.state.lang}/>
                </div>

                </div>

            </div>

        )
    }
    renderMobile(){
        return(
            <div dir={this.state.lang=="ar"?"rtl":"ltr"}>
                <Header page={'home'}  context={this}></Header>
                <div id={'homePage'}>
                    <ParallaxProvider>
                        <div id="HomePageContent" >
                            <div style={{marginBottom:150}}/>
                            <img src={require('../imgs/split.png')} style={{width:'100%',height:5,display:'flex',marginBottom:0}} />
                            <div className={'row volAndDivSectionMobile'}>
                                <div  className={'col-6 donateSection donateSectionMobile'}>
                                    <Link to="/donate"><button style={this.state.lang=='ar'?{fontSize:this.state.arSize,paddingLeft:'25px',paddingRight:'25px'}:{}}id='donateBtn' className={'donateVolMobileButtons'}>{Translator.getString('DONATE',this.state.lang)}</button></Link>
                                </div>
                                <div  className={'col-6 volSection volSectionMobile'}>
                                    <Link to="/volunteer"><button  style={this.state.lang=='ar'?{fontSize:this.state.arSize,paddingLeft:'25px',paddingRight:'25px'}:{}} id='volBtn' className={'donateVolMobileButtons'}>{Translator.getString('VOLUNTEER',this.state.lang)}</button></Link>
                                </div>
                            </div>
                            <img src={require('../imgs/split.png')} style={{width:'100%',height:5,display:'flex',marginBottom:0}} />
                            <div id='donAndVolText' className='ql-editor'
                                 dangerouslySetInnerHTML={{__html: this.state.donAndVolText}}></div>
                            <div className={' visionBack'}>
                                <div id={this.state.isMobile?'missionDivMobile':'missionDiv'}>
                                    <div style={{width: '100%', display: 'table'}}>
                                        <div className='ql-editor' style={{
                                            display: 'inline-block',
                                            verticalAlign: 'middle',
                                            textAlign:'center',
                                            width: '100%'
                                        }} dangerouslySetInnerHTML={{__html: this.state.visionTitle}}></div>
                                    </div>
                                    <div className='ql-editor' style={{height: '40%', width: '100%',textAlign:'center',}}
                                         dangerouslySetInnerHTML={{__html: this.state.visionText}}></div>
                                </div>
                            </div>
                            <div className={'missionBack'}>
                                <div id={this.state.isMobile?'missionDivMobile':'missionDiv'}>
                                    <div style={{width: '100%', display: 'table'}}>
                                        <div className='ql-editor' style={{
                                            display: 'inline-block',
                                            verticalAlign: 'middle',
                                            textAlign:'center',
                                            width: '100%'
                                        }} dangerouslySetInnerHTML={{__html: this.state.missionTitle}}></div>
                                    </div>
                                    <div className='ql-editor' style={{height: '40%', width: '100%',textAlign:'center',}}
                                         dangerouslySetInnerHTML={{__html: this.state.missionText}}></div>

                                </div>
                            </div>
                            <div style={{width: '95%',margin: 'auto'}}>
                                <HomeNewsBanner hideCallBack={this.hideNewsCallBack} context={this}/>
                            </div>
                            <img src={require('../imgs/split.png')} style={{width:'100%',height:5,display:'flex',marginTop:0,margin:0,padding:0}} />
                            <Parallax className={'whereWeWorkImageSection'} y={[0, '-15%']} tagOuter="figure" >
                                <div  className={this.state.lang=='ar'?'whereWeWorkImageSectionBackAr':'whereWeWorkImageSectionBack'}  style={{width:'100%',height:470}}/>
                                <div className={this.state.lang=='ar'?'whereWeWorkImageContentMobileAr':'whereWeWorkImageContentMobile'}>
                                    {this.state.lang=='ar'?<div dir={'rtl'}>
                                            <p style={{fontSize:'10px !important'}}>تعمل بذور من أجل الانسانية في 10 دول حول العالم</p>
                                            <Link to="/wherewework"><button><p className={'whereWeWorkButton'}>اكتشف المزيد</p></button></Link>
                                        </div>
                                        :<div>
                                            <p style={{fontSize:'10px !important'}}>Seeds of Humanity operates in 10 countries around the world</p>
                                            <Link to="/wherewework"><button><p className={'whereWeWorkButton'}>Discover more</p></button></Link>
                                        </div>}
                                </div>
                            </Parallax>
                            <img src={require('../imgs/split.png')} style={{width:'100%',height:5,display:'flex',marginTop:0,margin:0,padding:0}} />
                            <HomeRoundedTabs isMobile={this.state.isMobile} lang={this.state.lang}/>
                            <img src={require('../imgs/split.png')}
                                 style={{width: '100%', height: 5, display: 'flex', marginTop: 40, padding: 0}}/>
                            <div id='factsDiv'>
                                <h2>{Translator.getString("Fun facts about Seeds", this.state.lang)}</h2>
                                <div id='factItems' className='row'>
                                    <div className='factItem col-xl-3 col-lg-3 col-sm-6 col-6'>
                                        <h3>{this.state.volunteersNum}</h3>
                                        <p>{Translator.getString("Volunteers", this.state.lang)}</p>
                                    </div>
                                    <div className='factItem col-xl-3 col-lg-3 col-sm-6 col-6'>
                                        <h3>{this.state.countriesNum}</h3>
                                        <p>{Translator.getString("Countries they come from", this.state.lang)}</p>
                                    </div>
                                    <div className='factItem col-xl-3 col-lg-3 col-sm-6 col-6'>
                                        <h3>{this.state.hoursNum}</h3>
                                        <p>{Translator.getString("Hours of volunteering", this.state.lang)}</p>
                                    </div>
                                    <div className='factItem col-xl-3 col-lg-3 col-sm-6 col-6'>
                                        <h3>{this.getFactYear()}</h3>
                                        <p>{Translator.getString("Years", this.state.lang)}</p>

                                    </div>
                                </div>
                            </div>
                            <img src={require('../imgs/split.png')}
                                 style={{width: '100%', height: 5, display: 'flex', marginTop: 0, padding: 0}}/>

                        </div>
                    </ParallaxProvider>

                </div>
                <div style={{height:80}}>
                    <Footer lang={this.state.lang}/>
                </div>

            </div>
        )
    }
    render() {
        //return !this.state.isMobile?this.renderWeb():this.renderMobile()
        if(this.state.loading){
            return (
                <div style={{display:this.state.loading?'flex':'none',flexDirection:'column',justifyContent:'center',alignItems:'center',height:window.innerHeight,backgroundColor:'#fff',zIndex:5,position:'absolute',width:'100%'}}>
                    <img src={require('../logo.png')} style={{width:174,height:141}} />
                    <h2 className={'splashText'}>{this.state.lang=='ar'?'بذور من اجل الإنسانية':'Seeds of humanity'}</h2>
                    <div style={{width:350,height:150}}>

                        <Lottie
                            width={350}
                            height={350}
                            options={{
                                animationData: animation,
                                loop: true,

                            }}
                        />
                    </div>

                </div>
            )

        }
        window.addEventListener("resize", this.resize.bind(this));
        document.addEventListener('scroll', this.trackScrolling);
        return !this.state.isMobile?this.renderWeb():this.renderMobile()
    }


}

/*

<img src={handsImage} id='bottomImage'/>
<div  className='row' style={{width:'100%',marginLeft:'0px',marginRight:'0px'}}>
                        <div className='col-xl-6 col-md-6 col-lg-6 col-sm-12 ' style={{padding:'0px'}}>
                            <Link id='voleBtn'
                                  to={'/volunteer'}>{Translator.getString("VOLUNTEER", this.state.lang)}</Link>
                            <img id='volImg' src={this.state.volImg}></img>
                        </div>
                        <div className='col-xl-6 col-md-6 col-lg-6 col-sm-12' style={{padding:'0px'}}>
                            <Link id='donBtn' to={'/donate'}>{Translator.getString("DONATE", this.state.lang)}</Link>
                            <img id='donImg' src={this.state.donateImg}></img>
                        </div>
                    </div>
 */
