import axios from 'axios'
export default class ServerController {
   // email: string;
    //name: string;
    static UPLOADS="https://www.api.seedsofhumanity.org/"
    Url="https://www.api.seedsofhumanity.org/?";
    constructor() {

    }
    getHomeNews(callback,context){
        axios.get(this.Url+'todo=homeNews&action=getHomeNews')
            .then(res => {
                const data = res.data;
                console.log(data)
                callback(data.HomeNews,context)
            })
    }
    getSections(callback,context,from){
        const formData = new FormData();
        formData.append('page',from)

        axios.post(this.Url+"todo=Sections&action=getSections", formData)
            .then(res => {
                const data = res.data;
                callback(data.Sections,context)
            })

    }
    getWhereWeWorkSections(callback,context){
        const formData = new FormData();
        axios.post(this.Url+"todo=WhereWeWorkSections&action=getSections", formData)
            .then(res => {
                const data = res.data;
                callback(data.Sections,context)
            })
    }
    getHomePageEelements(callback,context,lang){

        axios.get(this.Url+'todo=HomePageElements&action=getHomePageEelements&lang='+lang)
            .then(res => {
                const data = res.data;
                callback(data.HomePageElements,context)
            })
    }
    getHomeRoundedTabs(callback,context,lang) {
        axios.get(this.Url + 'todo=HomeRoundedTabs&action=getHomeRoundedTabs&lang='+lang)
            .then(res => {
                const data = res.data;
                callback(data.HomeRoundedTabs, context)
            })
    }
    getOurTeam(callback,context){
        axios.get(this.Url + 'todo=OurTeam&action=getOurTeam')
            .then(res => {
                const data = res.data;
                callback(data.OurTeam, context)
            })
    }
    getOurVolunteers(callback,context){
        axios.get(this.Url + 'todo=OurVolunteers&action=get')
            .then(res => {
                const data = res.data;
                callback(data.OurVolunteers, context)
            })
    }
    getBoardMembers(callback,context){
        axios.get(this.Url + 'todo=BoardMembers&action=get')
            .then(res => {
                const data = res.data;
                callback(data.BoardMembers, context)
            })
    }
    getDonors(callback,context){
        axios.get(this.Url + 'todo=Donors&action=getDonors')
            .then(res => {
                const data = res.data;
                callback(data.Donors, context)
            })
    }
    getHomeSlider(callback,context){
        axios.get(this.Url + 'todo=HomeSlider&action=get')
            .then(res => {
                const data = res.data;
                callback(data.HomeSlider, context)
            })
    }
    uploadResume(file,email,name){
        const formData = new FormData();
        formData.append('email', email)
        formData.append('file', file)
        formData.append('name', name)
         axios.post(this.Url+"todo=Resume&action=uploadResume", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {

        })
    }
    fillVolForm(file,email,fullName,address,phone,birthDate,work,period,isValidPass,experience,note,lang,fbName){
        const formData = new FormData();
        formData.append('email', email)
        formData.append('file', file)
        formData.append('fullName', fullName);
        formData.append('address', address)
        formData.append('phone', phone)
        formData.append('birthDate', birthDate)
        formData.append('work', work)
        formData.append('period', period)
        formData.append('isValidPass', isValidPass)
        formData.append('experience', experience)
        formData.append('note', note)
        formData.append('lang', lang)
        formData.append('fbName', fbName)

        axios.post(this.Url+"todo=Resume&action=uploadResume", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {

        })
    }
    sendEmail(fullname,phone,email,text){
        const formData = new FormData();
        formData.append('full_name', fullname)
        formData.append('phone', phone)
        formData.append('email', email)
        formData.append('text', text)
        axios.post(this.Url+"todo=Donors&action=sendEmail", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {

        })
    }
    getDonateElements(callback,context){
        axios.get(this.Url + 'todo=DonateElements&action=get')
            .then(res => {
                const data = res.data;
                callback(data.DonateElements, context)
            })
    }

}
