import React,{Component} from 'react'

import Header from "../../components/Header";

import Footer from "../../components/Footer";
import ServerController from "../../utilities/ServerController";
import Layout1 from "../../components/Layouts/Layout1";
import Layout2 from "../../components/Layouts/Layout2";
import Layout3 from "../../components/Layouts/Layout3";
import Layout4 from "../../components/Layouts/Layout4";
import SectionContainer from "../../components/SectionContainer";
import { compose, withProps } from "recompose"
import { withScriptjs,withGoogleMap,GoogleMap, Marker } from "react-google-maps"

import Modal from "react-bootstrap/Modal";
import {Parallax, ParallaxProvider} from "react-scroll-parallax";
import TextEllipsis from "react-text-ellipsis";
const MyMapComponent = compose(
    withProps({
        googleMapURL:"https://maps.googleapis.com/maps/api/js?key=AIzaSyARdNOTVwI5ZMVxgTC562TlUZRaDCIIvNI&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `700px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
)((props) =>
    <GoogleMap
        defaultZoom={4}
        defaultCenter={{ lat: 36.72070365849803, lng: 49.73210660131 }}
        onClick={(e => props.callBack(e))}
    >
        {
            props.markers&&props.markers.map(m=>{

                return (<Marker    position={{ lat: parseFloat(m.lat), lng: parseFloat(m.lng)}} onClick={()=>props.onMarkerClick(m)} />)
            })
        }


    </GoogleMap>
)
export default class WhereWeWorkPage extends Component {
    constructor() {
        super();
        this.state = {hideConfimBtn:false,success:false,showDelete:false,sectionToDeleteId:-1,show:false,tabToShow:null,markers:null,markerToAdd:null,sections:null,lang:localStorage.getItem('lang')};
    }

    componentDidMount() {
        let controller=new ServerController();

        controller.getWhereWeWorkSections(this.handleData,this)
    }
    handleData(data,context){
        context.setState({sections:data});
    }
    handleMapClick(e){
        let locJson=JSON.stringify(e.latLng);
        let loc=JSON.parse(locJson);
        let lat=loc.lat;
        let lng=loc.lng;
        //this.setState({markerToAdd:{ lat: lat, lng: lng}})
    }
    handleMarkerClick(section){
        this.setState({tabToShow:section,show:true})
    }
    getSection(){
        if(this.state.tabToShow!=null){
            return (<Layout2 section={this.state.tabToShow} context={this} deleteFunc={this.showDeleteSectionModal}/>)
        }

    }

    openCountery(id){
        let section=this.state.sections.filter((f)=>f.id==id)[0];
            this.setState({tabToShow:section,show:true})
    }
    render() {
        return (
            <div dir={this.state.lang=="ar"?"rtl":"ltr"}>
                <Header page={'whatwedo'}  context={this}></Header>
                <ParallaxProvider>
                    <div id="HomePageContent">
                        <div id='homeSubPageContent'>
                            <button  style={{marginBottom:20,display:this.state.markerToAdd==null?'none':'flex'}} data-toggle="modal" className='editBtn2'data-target="#Layout2CreatorModalDiv">add
                            </button>
                            <MyMapComponent  markerToAdd={this.state.markerToAdd} markers={this.state.sections} callBack={(e)=>this.handleMapClick(e)} onMarkerClick={(section)=>this.handleMarkerClick(section)}/>
                            <div style={{marginTop:20,width:'100%',margin:0}}>
                                <img src={require('../../imgs/split.png')} style={{width:'100%',height:5,display:'flex',marginTop:20,margin:0,padding:0,marginBottom:50}} />
                                        <div className={'row countriesBackImg'} >
                                            <div className={'col-4'}>
                                                <p className={'areaText'}>{this.state.lang=='ar'?'الشرق الأوسط':'Middle East'}</p>
                                                {
                                                    this.state.sections&&this.state.sections.filter(s=>s.area=="1").map(s=>{
                                                       //return (<p onClick={()=>this.openCountery(37)} className={'counryNameText'}>{this.state.lang=='ar'?'الأردن':'Jordan'}</p>);
                                                    return (  <div onClick={()=>this.openCountery(s.id)} className={this.state.lang=="ar"?'ql-editor counryNameText':'ql-editor counryNameText'}   dangerouslySetInnerHTML={{__html: this.state.lang=="ar"?s.titleAr:s.title}}></div>)
                                                    })
                                                }
                                                {/*<p onClick={()=>this.openCountery(37)} className={'counryNameText'}>{this.state.lang=='ar'?'الأردن':'Jordan'}</p>
                                                <p onClick={()=>this.openCountery(11)} className={'counryNameText'}>{this.state.lang=='ar'?'اليمن':'Yemen'}</p>
                                                <p onClick={()=>this.openCountery(41)} className={'counryNameText'}>{this.state.lang=='ar'?'العراق':'Iraq'}</p>
                                                <p onClick={()=>this.openCountery(35)} className={'counryNameText'}>{this.state.lang=='ar'?'سوريا':'Syria'}</p>
                                                <p onClick={()=>this.openCountery(22)} className={'counryNameText'}>{this.state.lang=='ar'?'غزة':'Gaza'}</p>
                                                <p onClick={()=>this.openCountery(26)} className={'counryNameText'}>{this.state.lang=='ar'?'الضفة الغربية':'West Bank'}</p>*/}
                                            </div>
                                            <div className={'col-4'}>
                                                <p className={'areaText'}>{this.state.lang=='ar'?'أوروبا':'Europe'}</p>
                                                {
                                                    this.state.sections&&this.state.sections.filter(s=>s.area=="2").map(s=>{
                                                        //return (<p onClick={()=>this.openCountery(37)} className={'counryNameText'}>{this.state.lang=='ar'?'الأردن':'Jordan'}</p>);
                                                        return (  <div onClick={()=>this.openCountery(s.id)} className={this.state.lang=="ar"?'ql-editor counryNameText':'ql-editor counryNameText'}   dangerouslySetInnerHTML={{__html: this.state.lang=="ar"?s.titleAr:s.title}}></div>)
                                                    })
                                                }
                                                {
                                                    /*
                                                     <p onClick={()=>this.openCountery(20)} className={'counryNameText'}>{this.state.lang=='ar'?'اليونان':'Greece'}</p>
                                                <p onClick={()=>this.openCountery(19)} className={'counryNameText'}>{this.state.lang=='ar'?'صربيا':'Serbia'}</p>
                                                <p onClick={()=>this.openCountery(24)} className={'counryNameText'}>{this.state.lang=='ar'?'هنغاريا':'Hungary'}</p>
                                                     */
                                                }

                                            </div>
                                            <div className={'col-4'}>
                                                <p className={'areaText'}>{this.state.lang=='ar'?'آسيا':'Asia'}</p>
                                                {
                                                    this.state.sections&&this.state.sections.filter(s=>s.area=="3").map(s=>{
                                                        //return (<p onClick={()=>this.openCountery(37)} className={'counryNameText'}>{this.state.lang=='ar'?'الأردن':'Jordan'}</p>);
                                                        return (  <div onClick={()=>this.openCountery(s.id)} className={this.state.lang=="ar"?'ql-editor counryNameText':'ql-editor counryNameText'}   dangerouslySetInnerHTML={{__html: this.state.lang=="ar"?s.titleAr:s.title}}></div>)
                                                    })
                                                }
                                                {
                                                    /*
                                                    <p onClick={()=>this.openCountery(16)} className={'counryNameText'}>{this.state.lang=='ar'?'بنغلاديش':'Bangladesh'}</p>
                                                     */
                                                }



                                            </div>
                                        </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={this.state.show  } onHide={()=>{this.setState({show:false})}} id={'tabDetailsModal'} dir={this.state.lang=="ar"?"rtl":"ltr"} >
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            {this.getSection()}
                        </Modal.Body>

                    </Modal>
                </ParallaxProvider>
                <div  className={'footerCover'}>

                </div>
                <Footer lang={this.state.lang} />
            </div>
        )
    }
}
