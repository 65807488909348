import React,{Component} from 'react'

import Header from "../../components/Header";
import marker from "../../imgs/marker.png"
import phone from "../../imgs/phone.png"
import email from "../../imgs/email.png"
import {Link} from "react-router-dom";
import volImg from "../../imgs/vol.png";
import Footer from "../../components/Footer";
import ServerController from "../../utilities/ServerController";
import Layout1 from "../../components/Layouts/Layout1";
import Layout2 from "../../components/Layouts/Layout2";
import Layout3 from "../../components/Layouts/Layout3";
import Layout4 from "../../components/Layouts/Layout4";
import SectionContainer from '../../components/SectionContainer';

export default class MissionsAndGoalsPage extends Component {
    constructor() {
        super();
        this.state = {sections:null,lang:localStorage.getItem('lang')};
    }

    componentDidMount() {
        this.setState({page:this.props.page})
        let controller=new ServerController();

        controller.getSections(this.handleData,this,"MissionAndGoals")
    }
    handleData(data,context){
        context.setState({sections:data});
    }



    render() {
        return (
            <div dir={this.state.lang=="ar"?"rtl":"ltr"}>
                <Header page={'whoweare'}  context={this}></Header>
                <div id="pageContent">
                    <div id='subPageContent'>
                        <SectionContainer page={"MissionAndGoals"}/>
                    </div>
                </div>
                <div  className={'footerCover'}>

                </div>
                <Footer lang={this.state.lang} />
            </div>
        )
    }
}
