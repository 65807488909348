import React,{Component} from 'react'

import Header from "../../components/Header";

import Footer from "../../components/Footer";
import ServerController from "../../utilities/ServerController";
import Layout1 from "../../components/Layouts/Layout1";
import Layout2 from "../../components/Layouts/Layout2";
import Layout3 from "../../components/Layouts/Layout3";
import Layout4 from "../../components/Layouts/Layout4";
import SectionContainer from "../../components/SectionContainer";
import NewLayout from "../../components/Layouts/NewsLayout";
import Modal from "react-bootstrap/Modal";
import GalleryLayout from "../../components/Layouts/GalleryLayout";

export default class MediaPage extends Component {
    constructor() {
        super();
        this.state={isMobile:false,tabToShow:null,show:false,data:null,lang:localStorage.getItem('lang'),sectionToDeleteId:null,sections:null,sectionToEdit:null,page:null,hideConfimBtn:false,success:false,show1:false,show2:false,show3:false,show4:false,showDelete:false}
        ///this.state = {sections:null,lang:localStorage.getItem('lang')};
    }

    componentDidMount() {
        if(window.innerWidth<1090){
            this.setState({isMobile:true})
        }else{
            this.setState({isMobile:false})
        }
        let controller=new ServerController();
        window.addEventListener("resize", this.resize.bind(this));
        controller.getSections(this.handleData,this,'Media')
    }
    resize() {
        if(window.innerWidth<1090){
            this.setState({isMobile:true})
        }else{
            this.setState({isMobile:false})
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }
    componentDidUpdate() {

    }
    handleData(data,context){
        context.setState({sections:data});
    }
    goToSectionPage(section){
        this.setState({tabToShow:section,show:true})
    }
    getSections(){
        if(this.state.sections!=null){
            let secArray=[];
            for(let i=0;i<this.state.sections.length;i+=1){
                secArray.push(
                    <div  style={{width:'90%'}} dir={this.state.lang=="ar"?"rtl":"ltr"} className={'row newsPagesItem'}>
                        <GalleryLayout isMobile={this.state.isMobile} section={this.state.sections[i]} />
                    </div>

                )

            }

            return(
                <div>
                    {secArray}
                </div>

            )
        }

    }
    getSection(){
        if(this.state.tabToShow!=null){


            if(this.state.tabToShow.layoutType==1){
                return (<Layout1 section={this.state.tabToShow} />)
            }
            if(this.state.tabToShow.layoutType==2){
                return (<Layout2 section={this.state.tabToShow} />)
            }
            if(this.state.tabToShow.layoutType==3){
                return (<Layout3 section={this.state.tabToShow}  />)
            }
            if(this.state.tabToShow.layoutType==4){
                return(<Layout4 section={this.state.tabToShow}  />)
            }

        }

    }
    renderWeb() {
        return (
            <div dir={this.state.lang=="ar"?"rtl":"ltr"}>
                <Header page={'news'}  context={this}></Header>
                <div id="pageContent" >
                    <div id='subPageContent' >
                        <div className={'row'} >
                            <div className={'col-xl-10 col-lg-10 col-sm-12 col-12'} style={{margin:'auto'}} >
                                {this.getSections()}
                            </div>
                        </div>
                    </div>
                    <Modal show={this.state.show  } onHide={()=>{this.setState({show:false})}} id={'articalModal'} dir={this.state.lang=="ar"?"rtl":"ltr"} >
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            {this.getSection()}
                        </Modal.Body>

                    </Modal>
                </div>
                <div  className={'footerCover'}>

                </div>
                <Footer lang={this.state.lang} />
            </div>
        )
    }
    renderMobile() {
        return (
            <div dir={this.state.lang=="ar"?"rtl":"ltr"}>
                <Header page={'news'}  context={this}></Header>
                <div id="pageContent" >
                    <div id='subPageContent' >
                        {this.getSections()}
                    </div>
                    <Modal show={this.state.show  } onHide={()=>{this.setState({show:false})}} id={'articalModal'} dir={this.state.lang=="ar"?"rtl":"ltr"} >
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            {this.getSection()}
                        </Modal.Body>

                    </Modal>
                </div>
                <div  className={'footerCover'}>

                </div>
                <Footer lang={this.state.lang} />
            </div>
        )
    }
    render() {
        return !this.state.isMobile?this.renderWeb():this.renderWeb();
    }
}
//
