import React, {Component} from "react";
import Header from "../../components/Header";
import SectionContainer from "../../components/SectionContainer";
import Footer from "../../components/Footer";
import ServerController from "../../utilities/ServerController";
import Modal from "react-bootstrap/Modal";

export default class OurVolunteers extends Component {
    constructor() {
        super();
        this.state = {vols:null,lang:localStorage.getItem('lang'),show:false,tabToShow:null};
    }

    componentDidMount() {
        let controller=new ServerController();
        controller.getOurVolunteers(this.handleData,this);
    }
    handleData(data,context){
        context.setState({vols:data})
    }
    getVols(){
        let items=[];
        if(this.state.vols==null)
            return
        for(let i=0;i<this.state.vols.length;i++){
            items.push(<div className='teamItem col-xl-3 col-lg-4  col-sm-6  col-6' onClick={()=>this.setState({show:true,tabToShow:this.state.vols[i]})}>
                <img src={ServerController.UPLOADS+this.state.vols[i].img}/>
                <h6>{this.state.lang=='ar'?this.state.vols[i].nameAr:this.state.vols[i].name}</h6>
            </div>)
        }
        return(<div id='tabsInner' className='row'> {items}</div>)
    }
    getModalTitle(){
        if(this.state.tabToShow==null)
            return '';
        return this.state.lang=='ar'?this.state.tabToShow.nameAr:this.state.tabToShow.name
    }
    getModalImg(){
        if(this.state.tabToShow==null)
            return '';
        return this.state.tabToShow.img;
    }
    getModalText(){
        if(this.state.tabToShow==null)
            return '';
        return this.state.lang=='ar'?this.state.tabToShow.textAr:this.state.tabToShow.text
    }
    render() {
        return (
            <div dir={this.state.lang=="ar"?"rtl":"ltr"}>
                <Header page={'whoweare'}  context={this}></Header>
                <div id="pageContent">
                    <div id='subPageContent'>
                        <SectionContainer page={'OurVolunteers'}/><br/>
                        {this.getVols()}
                        <Modal show={this.state.show  } onHide={()=>{this.setState({show:false})}} id={'tabDetailsModal'} dir={this.state.lang=="ar"?"rtl":"ltr"} >
                            <Modal.Header closeButton>
                                <Modal.Title>{this.getModalTitle()}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <img src={ServerController.UPLOADS+this.getModalImg()} style={{width:'90%',margin:"auto",display:'block',height:'600px',objectFit:'contain'}}/><br/>
                                <div style={{textAlign:'center',width:'auto'}} class='ql-editor' dangerouslySetInnerHTML={{__html: this.getModalText()}}></div>

                            </Modal.Body>

                        </Modal>
                    </div>
                </div>
                <div  className={'footerCover'}>

                </div>
                <Footer lang={this.state.lang} />
            </div>
        )
    }
}
