import React,{Component} from 'react'
import '../../App.css';

import testNewsImg from '../../imgs/testnews.jpg'
import ServerController from "../../utilities/ServerController";
import Modal from "react-bootstrap/Modal";
import Layout1 from "./Layout1";
import Layout2 from "./Layout2";
import Layout3 from "./Layout3";
import Layout4 from "./Layout4";

export default class GalleryLayout extends Component {
    constructor() {
        super();
        this.state={isMobile:false,picIndex:-1,section:null,lang:localStorage.getItem('lang')}
    }
    componentDidMount() {
        let section=this.props.section;
        if(!(this.state.section==null || this.state.section.galleryImgs==null || this.state.section.galleryImgs=="null" || this.state.section.galleryImgs.length==0))
        {
            for(let i=0;i<section.galleryImgs;i++){
                section.galleryImgs[i].selected=false;
            }
        }
        this.setState({section:section})
    }
    getImageOrVideo(){
        if(this.state.section==null)
            return
        if(this.state.picIndex>=0){
            if(this.state.section==null || this.state.section.galleryImgs==null || this.state.section.galleryImgs=="null" || this.state.section.galleryImgs.length==0)
                return

            return (<img src={ServerController.UPLOADS+this.state.section.galleryImgs[this.state.picIndex].img } className={'gallerySelectedImg'}  style={{width:'100%',objectFit: 'cover'}}/>)


        }
        if(this.state.section.isVideo==0){
            if(this.state.section.img=='' ||this.state.section.img=='null'||this.state.section.img==null){
                return ;
            }
            return (<img src={this.state.section!=null?ServerController.UPLOADS+this.state.section.img:""} className={'gallerySelectedImg'}  style={{width:'100%',objectFit: 'cover'}}/>)

        }
        if(this.state.section.isVideo==1){
            if(this.state.section.img=='' ||this.state.section.img=='null'||this.state.section.img==null){
                return ;
            }
            return (<iframe class='youtubeIframe'className={'gallerySelectedImg'} src={this.state.section.img} style={{width:'100%'}}/>)
        }
    }
    getTitle(){
        if(this.state.section==null)
            return '';
        if(this.state.lang=='ar')
            return this.state.section.titleAr
        return this.state.section.title
    }
    getText(){
        if(this.state.section==null)
            return '';
        if(this.state.lang=='ar')
            return this.state.section.textAr
        return this.state.section.text
    }
    mouseEnter(id){
        let section=this.props.section;
        if(!(this.state.section==null || this.state.section.galleryImgs==null || this.state.section.galleryImgs=="null" || this.state.section.galleryImgs.length==0))
        {
            for(let i=0;i<section.galleryImgs.length;i++){
                if(section.galleryImgs[i].id==id){
                    if(section.galleryImgs[i].selected){
                        section.galleryImgs[i].selected=false;
                    }else{
                        section.galleryImgs[i].selected=true;
                    }

                }else{
                    section.galleryImgs[i].selected=false;
                }

            }
            this.setState({section:section})
        }

    }
    getGellary(){
        if(this.state.section==null || this.state.section.galleryImgs==null || this.state.section.galleryImgs=="null" || this.state.section.galleryImgs.length==0)
            return
        let list=[];
        for(let i=0;i<this.state.section.galleryImgs.length;i++){
            list.push(<div className={'galleryImgDiv'} onClick={()=>this.setState({picIndex:i})} >

                <img src={ServerController.UPLOADS+this.state.section.galleryImgs[i].img} style={{width:'100%',height:'130px',marginBottom:'5px',objectFit:'cover',display:'inline-block'}}/>


            </div>)
        }
        return(<div>
            <div className={'gallery'} style={{width:'100%'}}>{list}</div>
        </div>)
    }
    incPic(){
        let picIndex=this.state.picIndex+1;
        if(picIndex<this.state.section.galleryImgs.length){
            this.setState({picIndex:picIndex});
        }else{
            this.setState({picIndex:-1});
        }

    }
    decPic(){
        if(this.state.picIndex==-1){
            this.setState({picIndex:this.state.section.galleryImgs.length-1});
            return;
        }
        let picIndex=this.state.picIndex-1;
        if(picIndex<0){
            this.setState({picIndex:-1});
            return;
        }
        this.setState({picIndex:picIndex});

    }
    getSection(){
        if(this.state.section!=null){


            if(this.state.section.layoutType==1){
                return (<Layout1 section={this.state.section} />)
            }
            if(this.state.section.layoutType==2){
                return (<Layout2 section={this.state.section} />)
            }
            if(this.state.section.layoutType==3){
                return (<Layout3 section={this.state.section}  />)
            }
            if(this.state.section.layoutType==4){
                return(<Layout4 section={this.state.section}  />)
            }

        }

    }
    render() {
        return (
            <div  style={{width:'100%',overflow:'hidden',borderRight:this.state.lang=="ar"?'4px solid #9C2062':'0',borderLeft:this.state.lang=="ar"?'0':'4px solid #9C2062',paddingLeft:this.props.isMobile?'2px':'20px',paddingRight:this.props.isMobile?'2px':'20px',marginBottom:'20px',paddingBottom:'20px',paddingTop:'20px'}} dir={this.state.lang=="ar"?"rtl":"ltr"}>


                <div>
                    <div style={{paddingLeft:this.props.isMobile?'5px':0,paddingRight:this.props.isMobile?'5px':0}}>
                        <div className='ql-editor'   dangerouslySetInnerHTML={{__html: this.getTitle()}}></div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <button onClick={()=>this.decPic()}  style={{flex:this.props.isMobile?0.01:0.02,display:this.state.section&&this.state.section.galleryImgs!=null&&this.state.section.galleryImgs.length>0?'flex':'none',justifyContent:'center',alignItems:'center',backgroundColor:'#efefef',border:0,boxShadow: "3px 3px 3px #efefef"}}>
                            <img src={require('../../imgs/nextIcon@2x.png')} style={{width:this.props.isMobile?10:15,height:this.props.isMobile?15:20,transform:this.state.lang=='ar'?'rotate(0deg)': 'rotate(180deg)',marginTop:15}}/>
                        </button>
                        <div style={{flex:this.props.isMobile?0.98:0.96,marginTop:'20px',overflow:'hidden',display:this.props.isMobile?'block':'flex',marginBottom:5}}>
                            {this.getImageOrVideo()}
                        </div>
                        <button onClick={()=>this.incPic()} style={{flex:this.props.isMobile?0.01:0.02,display:this.state.section&&this.state.section.galleryImgs!=null&&this.state.section.galleryImgs.length>0?'flex':'none',justifyContent:'center',alignItems:'center',backgroundColor:'#efefef',border:0,boxShadow: "3px 3px 3px #efefef"}}>
                            <img src={require('../../imgs/nextIcon@2x.png')} style={{width:this.props.isMobile?10:15,height:this.props.isMobile?15:20,marginTop:15,transform:this.state.lang=='ar'?'rotate(180deg)': 'rotate(0deg)'}}/>
                        </button>
                    </div>
                    <div style={{display:'flex',flexDirection:'row'}}>

                        <div style={{paddingLeft:6,paddingRight:10,flex:this.props.isMobile?0.98:0.96,margin:'auto',overflow:'hidden',display:this.props.isMobile?'block':'flex'}}>
                            {this.getGellary()}
                        </div>

                    </div>

                </div>
                <br/><br/>
                <Modal show={this.state.showSection  } onHide={()=>{this.setState({showSection:false})}} id={'articalModal'} dir={this.state.lang=="ar"?"rtl":"ltr"} >
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {this.getSection()}
                    </Modal.Body>

                </Modal>
            </div>
        )
    }


}
