import React,{Component} from 'react'

import Header from "../components/Header";
import marker from "../imgs/marker.png"
import phone from "../imgs/phone.png"
import email from "../imgs/email.png"
import {Link} from "react-router-dom";
import volImg from "../imgs/vol.png";
import Footer from "../components/Footer";
import Translator from "../utilities/Translator";
import ServerController from "../utilities/ServerController";

import {Overlay, OverlayTrigger} from "react-bootstrap";
import Popover from 'react-bootstrap/Popover';
export default class ContactPage extends Component {
    constructor() {
        super();
         this.state = {lang:localStorage.getItem('lang'),fname:'',lname:'',phone:'',email:'',text:'',showMsg:false,target:null};
    }

    componentDidMount() {

    }
    sendEmail(event){
        this.setState({showMsg:true,target:event.target})
        let fullname=this.state.fname+" "+this.state.lname;
        let controller=new ServerController();
        if(this.state.text!=null){
            controller.sendEmail(fullname,this.state.phone,this.state.email,this.state.text);
        }

    }
    render() {

        return (
            <div dir={this.state.lang=="ar"?"rtl":"ltr"}>
                <Header page={'contact'} context={this}></Header>
                <div id="pageContent">
                    <div id='subPageContent'>
                        <div class='row'>

                            <div className='col-xl-6 col-lg-6 col-sm-12 col-12' id='seedInfoDiv'>
                                <h4>{Translator.getString("GREECE",this.state.lang)}</h4>
                                <div class='contactInfo'>
                                    <div class='contactInfoItem'>
                                        <img src={marker}></img><p>{Translator.getString("167, Konstantinoupoleos av , TK 10441, Athens, Greece",this.state.lang)}</p>
                                    </div>
                                    <div class='contactInfoItem'>
                                        <a href="mailto:seedsofhumanityhellas@gmail.com"> <img src={email}></img><p>seedsofhumanityhellas@gmail.com</p></a>
                                    </div>
                                    <div class='contactInfoItem'>
                                        <a href="tel:0030 213 0445630"><img src={phone}></img><p>0030 213 0445630</p></a>
                                    </div>
                                </div>
                                <h4>{Translator.getString("PALESTINE",this.state.lang)}</h4>
                                <div className='contactInfo'>
                                    <div className='contactInfoItem'>
                                        <img src={marker}></img><p>{Translator.getString("Tamra , palestine",this.state.lang)}</p>
                                    </div>
                                    <div className='contactInfoItem'>
                                        <a href="mailto:seedsofhumanity@gmail.com"> <img src={email}></img><p>seedsofhumanity@gmail.com</p></a>
                                    </div>
                                    <div className='contactInfoItem'>
                                        <a href="tel:+972 4 8669955"><img src={phone}></img><p>04-8669955</p></a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-sm-12 col-12'>
                                <div id='Volform'>
                                    <input type='text' onChange={event => this.setState({fname:event.target.value})} className='volForms' placeholder={Translator.getString("First Name",this.state.lang)} ></input>
                                    <input type='text' onChange={event => this.setState({lname:event.target.value})} className='volForms' placeholder={Translator.getString("last Name",this.state.lang)}></input>
                                    <input type='text' onChange={event => this.setState({phone:event.target.value})} className='volForms' placeholder={Translator.getString('Phone number',this.state.lang)}></input>
                                    <input type='text' onChange={event => this.setState({email:event.target.value})} className='volForms' placeholder={Translator.getString('Email',this.state.lang)}></input>
                                    <textarea className='volForms'onChange={event => this.setState({text:event.target.value})}  placeholder={Translator.getString('Your question or query',this.state.lang)}></textarea>

                                    <button id='submitBtn' type="submit" onClick={(event)=>this.sendEmail(event)} >{Translator.getString("Send",this.state.lang)} </button>
                                    <Overlay show={this.state.showMsg} rootClose={false} onHide={()=>alert("hide")}>
                                        <Popover id="popover-basic" >

                                            <Popover.Content>
                                                thanks for contacting seeds,we will contact you as soon as possible
                                            </Popover.Content>
                                        </Popover>
                                    </Overlay>
                                    <Overlay
                                        show={this.state.showMsg}
                                        target={this.state.target}
                                        placement="bottom"
                                        rootClose={true}
                                        onHide={()=>this.setState({showMsg:false})}
                                        containerPadding={20}
                                    >
                                        <Popover id="popover-contained">
                                            <Popover.Content>
                                                {Translator.getString("contactThanx",this.state.lang)}
                                            </Popover.Content>
                                        </Popover>
                                    </Overlay>
                                </div>
                            </div>


                        </div>
                        <br/>
                        <br/>
                    </div>
                </div>

                <Footer animation={false} lang={this.state.lang}/>
            </div>
        )
    }
}
