import React from 'react';
import Home  from './pages/Home'
import './App.css';
import {Route,Switch} from 'react-router-dom'
import VolunteerPage from "./pages/VolunteerPage";
import DonatePage from "./pages/DonatePage";
import ContactPage from "./pages/ContactPage";
import MissionsAndGoalsPage from "./pages/WhoWeArePages/MissionsAndGoalsPage";
import OurTeamPage from "./pages/WhoWeArePages/OurTeamPage";
import OurStoryPage from "./pages/WhoWeArePages/OurStoryPage";
import OurPartners from "./pages/WhoWeArePages/OurPartners";
import CommunityPage from "./pages/WhatWeDoPages/CommunityPage";
import OurModelPage from "./pages/WhatWeDoPages/OurModelPage";
import ProgramsPage from "./pages/WhatWeDoPages/ProgramsPage";
import WhereWeWorkPage from "./pages/WhatWeDoPages/WhereWeWorkPage";
import ConferencesPage from "./pages/NewAndEventsPages/ConferencesPage";
import MediaPage from "./pages/NewAndEventsPages/MediaPage";
import MissionPage from "./pages/NewAndEventsPages/MissionPage";
import BoardMembersPage from "./pages/WhoWeArePages/BoardMembersPage";
import OurVolunteers from "./pages/WhoWeArePages/OurVolunteers";
import MediaAndArticlesPage from "./pages/NewAndEventsPages/MediaAndArticlesPage";

import ReactGA from 'react-ga';


function App() {
    //ReactGA.initialize('');
    let lang=localStorage.getItem('lang');
    if(lang==null || lang=="null") {
        localStorage.setItem('lang', 'en')
    }

  return (
    <div className="App"  >
        <Switch>
            /*en */
                <Route path="/" exact component={Home}/>
                <Route path="/volunteer" exact component={VolunteerPage}/>
                <Route path="/donate" exact component={DonatePage}/>
                <Route path="/contact" exact component={ContactPage}/>
                /*who we are*/
                <Route path="/missionsandvision" exact  component={MissionsAndGoalsPage} />
                <Route path="/ourteam" exact component={OurTeamPage}/>
                <Route path="/boardmembers" exact component={BoardMembersPage}/>
                <Route path="/ourstory" exact component={OurStoryPage}/>
                <Route path="/ourvolunteers" exact component={OurVolunteers}/>
                <Route path="/ourpartners" exact component={OurPartners}/>
                /*who we are end*/
                /*what we do */
                <Route path="/programs" exact component={ProgramsPage}/>
                <Route path="/wherewework" exact component={WhereWeWorkPage}/>
                /*what we do end */
                /*news&events */
                <Route path="/conferences" exact component={ConferencesPage}/>
                <Route path="/media" exact component={MediaPage}/>
                <Route path="/mission" exact component={MissionPage}/>
                 <Route path="/mediaAndarticles" exact component={MediaAndArticlesPage}/>
                /*news&events  end */
            /*ar*/

        </Switch>
    </div>
  );
}

export default App;
