import React,{Component} from 'react'

import Header from "../../components/Header";

import Footer from "../../components/Footer";
import ServerController from "../../utilities/ServerController";
import Layout1 from "../../components/Layouts/Layout1";
import Layout2 from "../../components/Layouts/Layout2";
import Layout3 from "../../components/Layouts/Layout3";
import Layout4 from "../../components/Layouts/Layout4";
import SectionContainer from "../../components/SectionContainer";
import {Parallax, ParallaxProvider} from "react-scroll-parallax";
import {Link} from "react-router-dom";

export default class OurStoryPage extends Component {
    constructor() {
        super();
        this.state = {section:null,lang:localStorage.getItem('lang')};
    }
    componentDidMount() {
        this.setState({page:this.props.page})
        let controller=new ServerController();

        controller.getSections(this.handleData,this,"OurStory")
    }
    handleData(data,context){
        context.setState({section:data[0]});
    }

    getText(){
        if(this.state.section==null)
            return '';
        if(this.state.lang=='ar')
            return this.state.section.textAr
        return this.state.section.text
    }


    render() {
        /*return (
            <div dir={this.state.lang=="ar"?"rtl":"ltr"}>
                <Header page={'whoweare'}  context={this}></Header>

                <div id="HomePageContent">

                    <div id='homeSubPageContent'>
                        <ParallaxProvider>
                        <Parallax className={'ourStorySection'}  y={[0, -30]} tagOuter="figure" >
                            <img src={this.state.section!=null?ServerController.UPLOADS+this.state.section.img:""} style={{width:'100%',height:'800px',objectFit: 'cover'}}/>
                        </Parallax>
                        <div style={{paddingLeft:30,paddingRight:30,marginBottom:200}}>
                            <div className='ql-editor'  dangerouslySetInnerHTML={{__html: this.getText() }}></div>
                        </div>
                        </ParallaxProvider>
                    </div>

                </div>

                <div  className={'footerCover'}>

                </div>

                <Footer lang={this.state.lang} />

            </div>

        )*/
        return (
            <div dir={this.state.lang=="ar"?"rtl":"ltr"}>
                <Header page={'whoweare'}  context={this}></Header>
                <div id="HomePageContent">

                <div id='homeSubPageContent' style={{height:1000}}>
                    <ParallaxProvider>
                        <Parallax className={'ourStorySection'}  y={[0, -30]} tagOuter="figure" >
                            <img src={this.state.section!=null?ServerController.UPLOADS+this.state.section.img:""} style={{width:'100%',height:'800px',objectFit: 'cover'}}/>
                        </Parallax>
                        <div style={{paddingLeft:30,paddingRight:30}}>
                            <div className='ql-editor'  dangerouslySetInnerHTML={{__html: this.getText() }}></div>
                        </div>
                    </ParallaxProvider>
                </div>

            </div>
                <div  className={'footerCover'}>

                </div>
                <Footer lang={this.state.lang} />
            </div>
        )
    }
}
