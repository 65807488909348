import React,{Component} from 'react'
import '../../App.css';

import testNewsImg from '../../imgs/testnews.jpg'
import ServerController from "../../utilities/ServerController";
import Modal from "react-bootstrap/Modal";
import Layout1 from "./Layout1";
import Layout2 from "./Layout2";
import Layout3 from "./Layout3";
import Layout4 from "./Layout4";
import TextEllipsis from 'react-text-ellipsis';

export default class NewLayout extends Component {
    constructor() {
        super();
        this.state={isMobile:false,picIndex:0,section:null,lang:localStorage.getItem('lang')}
    }
    componentDidMount() {
        this.setState({section:this.props.section});
        if(window.innerWidth<1090){
            this.setState({isMobile:true})
        }else{
            this.setState({isMobile:false})
        }
        window.addEventListener("resize", this.resize.bind(this));
    }
    resize() {
        if(window.innerWidth<1090){
            this.setState({isMobile:true})
        }else{
            this.setState({isMobile:false})
        }
    }
    getImageOrVideo(){
        if(this.state.section==null)
            return
        if(this.state.picIndex>0){
            if(this.state.section==null || this.state.section.galleryImgs==null || this.state.section.galleryImgs=="null" || this.state.section.galleryImgs.length==0)
                return

            return (<img src={ServerController.UPLOADS+this.state.section.galleryImgs[this.state.picIndex-1].img}  style={{width:'80%',height:this.state.isMobile?'200px':'350px',objectFit: 'contain'}}/>)


        }
        if(this.state.section.isVideo==0){
            if(this.state.section.img=='' ||this.state.section.img=='null'||this.state.section.img==null){
                return ;
            }
            return (<img src={this.state.section!=null?ServerController.UPLOADS+this.state.section.img:""}  style={{width:'100%',height:this.state.isMobile?'200px':'350px',objectFit: 'contain'}}/>)

        }
        if(this.state.section.isVideo==1){
            if(this.state.section.img=='' ||this.state.section.img=='null'||this.state.section.img==null){
                return ;
            }
            return (<iframe class='youtubeIframe' src={this.state.section.img} style={{width:'100%',height:this.state.isMobile?'200px':'350px'}}/>)
        }
    }
    getTitle(){
        if(this.state.section==null)
            return '';
        if(this.state.lang=='ar')
            return this.state.section.titleAr
        return this.state.section.title
    }
    getText(){
        if(this.state.section==null)
            return '';
        if(this.state.lang=='ar')
            return this.state.section.textAr
        return this.state.section.text
    }
    getGellary(){
        if(this.state.section==null || this.state.section.galleryImgs==null || this.state.section.galleryImgs=="null" || this.state.section.galleryImgs.length==0)
            return
        let list=[];
        for(let i=0;i<this.state.section.galleryImgs.length;i++){
            list.push(<div className='col-4'>

                <img src={ServerController.UPLOADS+this.state.section.galleryImgs[i].img} style={{width:'100%',height:'400px',marginBottom:'5px'}}/>
            </div>)
        }
        return(<div>
            <div className={'row'}>{list}</div></div>)
    }
    incPic(){
        let picIndex=this.state.picIndex+1;
        if(picIndex<=this.state.section.galleryImgs.length){
            this.setState({picIndex:picIndex});
        }else{
            this.setState({picIndex:0});
        }

    }
    decPic(){
        let picIndex=this.state.picIndex-1;
        if(picIndex<0){
            this.setState({picIndex:this.state.section.galleryImgs.length});
            return;
        }
        if(picIndex>=1){
            this.setState({picIndex:picIndex});
        }else{
            this.setState({picIndex:0});
        }

    }
    getSection(){
        if(this.state.section!=null){


            if(this.state.section.layoutType==1){
                return (<Layout1 section={this.state.section} />)
            }
            if(this.state.section.layoutType==2){
                return (<Layout2 section={this.state.section} />)
            }
            if(this.state.section.layoutType==3){
                return (<Layout3 section={this.state.section}  />)
            }
            if(this.state.section.layoutType==4){
                return(<Layout4 section={this.state.section}  />)
            }

        }

    }
    render() {
        return (
            <div  style={{width:'100%',height:'570px',overflow:'hidden',borderRight:this.state.lang=="ar"?'4px solid #9C2062':'0',borderLeft:this.state.lang=="ar"?'0':'4px solid #9C2062',paddingLeft:'20px',paddingRight:'20px',marginBottom:'20px',paddingBottom:'20px',paddingTop:'20px'}} dir={this.state.lang=="ar"?"rtl":"ltr"}>


                <div>
                    <div className='ql-editor'   dangerouslySetInnerHTML={{__html: this.getTitle()}}></div>
                    <div >

                        {this.getImageOrVideo()}

                    </div>

                    <div >
                        <TextEllipsis
                            lines={5}
                            ellipsisChars={'...'}
                            tagClass={'className'}
                            debounceTimeoutOnResize={0}
                            useJsOnly={true}
                            onResult={(result) => {
                                if (result === TextEllipsis.RESULT.TRUNCATED)
                                    console.log('text get truncated');
                                else
                                    console.log('text does not get truncated');
                            }}>
                            <div className='ql-editor'  dangerouslySetInnerHTML={{__html: this.getText() }}></div>
                        </TextEllipsis>

                    </div>
                </div>
                <br/><br/>
                <Modal show={this.state.showSection  } onHide={()=>{this.setState({showSection:false})}} id={'articalModal'} dir={this.state.lang=="ar"?"rtl":"ltr"} >
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {this.getSection()}
                    </Modal.Body>

                </Modal>
            </div>
        )
    }


}
