import React,{Component} from 'react'
import '../App.css';
import facebookWhite from '../imgs/facebookWhite.png'
import twitterWhite from '../imgs/twitterWhite.png'
import instaWhite from '../imgs/instaWhite.png'
import youtube from '../imgs/youtube.png'
import {Link} from "react-router-dom";
import Translator from "../utilities/Translator";
import marker from "../imgs/markerWhite.png";
import email from "../imgs/mail-2.png";
import phone from "../imgs/phone-2.png";
export default class Footer extends Component {
    constructor() {
        super();
        this.state ={scrolledBottom:true,donateVal:50,lang:localStorage.getItem('lang')}
    }
    componentDidMount() {

            this.setState({lang: this.props.lang})

        /*if(this.props.animation==false) {
            this.setState({scrolledBottom: true})
        }
        else {
            window.addEventListener('scroll', () => this.handleScroll());
        }*/
    }

    componentDidUpdate(prevProps) {
        if(this.state.lang!=prevProps.lang)
            this.setState({lang:prevProps.lang})
    }
    handleScroll() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight-200) {

            this.setState({scrolledBottom:true})
        }
        if(windowBottom<=docHeight/2)
        {
            this.setState({scrolledBottom:false})
        }
    }
    donateRangeChange(event){
        this.setState({donateVal:event.target.value})
    }
    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }
    render() {
        return (
            <div style={{width:'100%'}}>
                <div id={"FooterDiv"}  class='row' dir={this.state.lang=="ar"?"rtl":"ltr"}>
                    <div id='footerContent' class='row'>
                        <div id='footerTitle' class='col-2 footerCol'>
                            <div id='footerSocialLinks' >
                                <div className='footerSocialLink'>
                                    <a href={'https://www.facebook.com/seedsofhumanity'} target={'_blank'}><img
                                        src={facebookWhite}/>
                                        <p>{Translator.getString("Facebook", this.state.lang)}</p></a>
                                </div>
                                <div className='footerSocialLink'>
                                    <a href={'https://twitter.com/Seeds_Humanity'} target={'_blank'}><img
                                        src={twitterWhite}/>
                                        <p>{Translator.getString("Twitter", this.state.lang)}</p></a>
                                </div>
                                <div className='footerSocialLink'>
                                    <a href={'https://www.instagram.com/seeds_of_humanity/'} target={'_blank'}><img
                                        src={instaWhite}/>
                                        <p>{Translator.getString("Instagram", this.state.lang)}</p></a>
                                </div>
                                <div className='footerSocialLink'>
                                    <a href={'https://www.youtube.com/channel/UCRwafWzlv69ZREDLonz53uw'}
                                       target={'_blank'}><img src={youtube}/>
                                        <p>{Translator.getString("Youtube", this.state.lang)}</p></a>
                                </div>
                            </div>
                            <h5>{Translator.getString("seeds of humanity",this.state.lang)}</h5>
                        </div>


                        <div id='footerAddress' className='col-10'>
                            <div class='row'>
                                <div className='col-5'>
                                    <h4 className={'footerCentry'} style={{color:'#ffffff',width:'fit-content'}}>{Translator.getString("GREECE", this.state.lang)}</h4>
                                    <div className='contactInfo'>
                                        <div className='contactInfoItem' style={{marginBottom:'5px'}}>
                                            <img src={marker}></img>
                                            <p style={{marginLeft:'10px',marginRight:'10px'}}>{Translator.getString("167, Konstantinoupoleos av , TK 10441, Athens, Greece", this.state.lang)}</p>
                                        </div>
                                        <div className='contactInfoItem' style={{marginBottom:'5px'}}>
                                            <a href="mailto:seedsofhumanityhellas@gmail.com"> <img src={email}></img>
                                                <p style={{marginLeft:'10px',marginRight:'10px'}}>seedsofhumanityhellas@gmail.com</p></a>
                                        </div>
                                        <div className='contactInfoItem'>
                                            <a href="tel:0030 213 0445630"><img src={phone}></img>
                                                <p style={{marginLeft:'10px',marginRight:'10px'}}>0030 213 0445630</p></a>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-7'>
                                    <h4 className={'footerCentry'} style={{color:'#ffffff',width:'fit-content'}}>{Translator.getString("PALESTINE", this.state.lang)}</h4>
                                    <div className='contactInfo'>
                                        <div className='contactInfoItem' style={{marginBottom:'5px'}}>
                                            <img src={marker}></img>
                                            <p style={{marginLeft:'10px',marginRight:'10px'}}>{Translator.getString("Tamra , palestine", this.state.lang)}</p>
                                        </div>
                                        <div className='contactInfoItem' style={{marginBottom:'10px'}}>
                                            <a href="mailto:seedsofhumanity@gmail.com"> <img src={email}></img>
                                                <p style={{marginLeft:'10px',marginRight:'10px'}}>seedsofhumanity@gmail.com</p></a>
                                        </div>
                                        <div className='contactInfoItem'>
                                            <a href="tel:+972 4 8669955"><img src={phone}></img><p style={{marginLeft:'10px',marginRight:'10px'}}>04-8669955</p></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='FooterMobileDiv'>
                    <div class='row'>
                        <div id='footerTitleMobile' >
                            <p>{Translator.getString("seeds of humanity", this.state.lang)}</p>
                        </div>

                    </div>
                </div>
            </div>
        )
    }


}
/*

 <div id={"FooterDiv"} class='row' dir={this.state.lang=="ar"?"rtl":"ltr"}>
                    <div id='footerContent' class='row'>
                        <div id='footerTitle' class='col-2 footerCol'>
                            <p>{Translator.getString("seeds of humanity",this.state.lang)}</p>
                        </div>
                        <div id='footerLinks' class='col-2'>
                            <Link class='footerLink' to='/ourstory'>{Translator.getString("About us",this.state.lang)}</Link>
                            <Link class='footerLink' to='/contact'>{Translator.getString("Contact",this.state.lang)}</Link>
                        </div>
                        <div id='footerSocialLinks' class='col-2'>
                            <div class='footerSocialLink'>
                                <a href={'https://www.facebook.com/seedsofhumanity'} target={'_blank'}><img src={facebookWhite}/>
                                <p>{Translator.getString("Facebook",this.state.lang)}</p></a>
                            </div>
                            <div className='footerSocialLink'>
                                <a href={'https://twitter.com/Seeds_Humanity'} target={'_blank'}><img src={twitterWhite}/>
                                <p>{Translator.getString("Twitter",this.state.lang)}</p></a>
                            </div>
                            <div className='footerSocialLink'>
                                <a href={'https://www.instagram.com/seeds_of_humanity/'} target={'_blank'}><img src={instaWhite}/>
                                <p>{Translator.getString("Instagram",this.state.lang)}</p></a>
                            </div>
                            <div className='footerSocialLink'>
                                <a href={'https://www.youtube.com/channel/UCRwafWzlv69ZREDLonz53uw'} target={'_blank'}><img src={youtube}/>
                                <p>{Translator.getString("Youtube",this.state.lang)}</p></a>
                            </div>
                        </div>
                        <div id='footerDonate' className='col-4'>
                            <h3>{Translator.getString("Donate",this.state.lang)}</h3>
                            <div style={{position:'relative',width:'50%',display:'inline-block'}}>
                            <input type="range" min="1" max="1000" className="slider"  value={this.state.donateVal} id="donateRange" onChange={event => this.donateRangeChange(event)}>

                            </input>
                            <p id={this.state.lang=="ar"?"footerDonateValAr":"footerDonateVal"}>{this.state.donateVal}</p>
                            </div>
                            <Link to={{
                                pathname: '/donate',
                                state: {
                                    donateVal: this.state.donateVal
                                }
                            }}><button id='footerDonateBtn'>{Translator.getString("Donate Now",this.state.lang)}</button></Link>
                        </div>
                        <div id='footerAddress' className='col-2'>
                            <p>{Translator.getString("baqafooter",this.state.lang)}</p>
                            <a href="mailto:seedsofhumanity@gmail.com"><p>seedsofhumanity@gmail.com</p></a>
                            <a href="tel:+972 4 8669955"><p>04-8669955</p></a>
                        </div>
                    </div>
                </div>
 */
