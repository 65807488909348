import React,{Component} from 'react'
import '../../App.css';

import testNewsImg from '../../imgs/testnews.jpg'
import ServerController from "../../utilities/ServerController";

export default class Layout1 extends Component {
    constructor() {
        super();
        this.state={section:null,lang:localStorage.getItem('lang')}
    }
    componentDidMount() {
        this.setState({section:this.props.section})

    }
    getImageOrVideo(){
        if(this.state.section==null)
            return
        if(this.state.section.isVideo==0){
            if(this.state.section.img=='' ||this.state.section.img=='null'||this.state.section.img==null){
                return ;
            }
            return (<img src={this.state.section!=null?ServerController.UPLOADS+this.state.section.img:""} style={{width:'100%',height:'500px',objectFit: 'cover'}}/>)

        }
        if(this.state.section.isVideo==1){
            if(this.state.section.img=='' ||this.state.section.img=='null'||this.state.section.img==null){
                return ;
            }
            return (<iframe class='youtubeIframe' src={this.state.section.img} style={{width:'100%',height:'500px'}}/>)
        }
    }
    getTitle(){
        if(this.state.section==null)
            return '';
        if(this.state.lang=='ar')
            return this.state.section.titleAr
        return this.state.section.title
    }
    getText(){
        if(this.state.section==null)
        return '';
        if(this.state.lang=='ar')
            return this.state.section.textAr
        return this.state.section.text
    }
    getGellary(){
        if(this.state.section==null || this.state.section.galleryImgs==null || this.state.section.galleryImgs=="null" || this.state.section.galleryImgs.length==0)
            return
        let list=[];
        for(let i=0;i<this.state.section.galleryImgs.length;i++){
            list.push(<div className='col-4'>

                <img src={ServerController.UPLOADS+this.state.section.galleryImgs[i].img} style={{width:'100%',height:'300px',marginBottom:'5px'}}/>
            </div>)
        }
        return(<div>
            <div className={'row'}>{list}</div></div>)
    }
    render() {
        return (
            <div dir={this.state.lang=="ar"?"rtl":"ltr"}>

                <div className='ql-editor' dangerouslySetInnerHTML={{__html: this.getTitle()}}></div>
                <div class='row'>

                    <div class='col-xl-7 col-lg-7 col-sm-7 col-12'>
                        {this.getImageOrVideo()}
                    </div>
                    <div className='col-xl-5 col-lg-5 col-sm-5 col-12'>

                        <div className='ql-editor' dangerouslySetInnerHTML={{__html: this.getText() }}></div>
                    </div>
                </div>
                <br/><br/>
                {this.getGellary()}
            </div>
        )
    }


}
