import React,{Component} from 'react'

import Header from "../../components/Header";

import Footer from "../../components/Footer";
import ServerController from "../../utilities/ServerController";
import Layout1 from "../../components/Layouts/Layout1";
import Layout2 from "../../components/Layouts/Layout2";
import Layout3 from "../../components/Layouts/Layout3";
import Layout4 from "../../components/Layouts/Layout4";
import SectionContainer from "../../components/SectionContainer";
import HomeRoundedTabs from "../../components/HomeRoundedTabs";

export default class ProgramsPage extends Component {
    constructor() {
        super();
        this.state = {isMobile:false,sections:null,lang:localStorage.getItem('lang')};
    }

    componentDidMount() {
        if(window.innerWidth<1090){
            this.setState({isMobile:true})
        }else{
            this.setState({isMobile:false})
        }
        window.addEventListener("resize", this.resize.bind(this));
    }
    resize() {
        if(window.innerWidth<1090){
            this.setState({isMobile:true})
        }else{
            this.setState({isMobile:false})
        }
    }
    render() {
        return (
            <div dir={this.state.lang=="ar"?"rtl":"ltr"}>
                <Header page={'whatwedo'}  context={this}></Header>
                <div id="pageContent">
                    <div id='subPageContent'>
                        <div style={{height:1400}}>
                            <HomeRoundedTabs isMobile={this.state.isMobile} isProgramsPage={true} lang={this.state.lang}/>
                            <SectionContainer page={'Programs'}/>
                        </div>

                    </div>
                </div>
                <div  className={'footerCover'}>

                </div>
                <Footer lang={this.state.lang} />
            </div>
        )
    }
}
