import React,{Component} from 'react'
import axios from 'axios';
import cors from 'cors'
import Header from "../components/Header";
import {Link} from "react-router-dom";
import volImg from "../imgs/vol.png";
import Footer from "../components/Footer";
import Translator from "../utilities/Translator";
import SectionContainer from "../components/SectionContainer";
import ServerController from "../utilities/ServerController";
import Popover from "react-bootstrap/Popover";
import {Overlay} from "react-bootstrap";
import Lottie from 'lottie-react-web'
import animation from '../success_loader.json'
import {

    useParams
} from "react-router-dom";
export default class VolunteerPage extends Component {
    constructor() {
        super();
        this.state={errors:{fname:false,email:false,address:false,phone:false,birthdate:false,work:false,volTime:false,volExp:false,resumeFileName:false},
            isSended:false,lang:localStorage.getItem('lang'),fname:"",lname:"",email:"",address:"",phone:"",birthdate:"",fb:'',work:'',volTime:'',validPass:'yes',volExp:'',notes:'',resumeFile:'',resumeFileName:'',showMsg:false,target:null}
        // this.state = {fbhover: false,twhover:false};
        //https://docs.google.com/forms/d/e/1FAIpQLSdgirHiERWz5nJxbTWvt8ok4wZfoO04vZDAI847L7M9ABMp4Q/viewform?entry.151339263=mohnad47@gmail.com&entry.214162399=mohnad&entry.121868771=iksal&entry.688748620=0546123203&entry.337248038=mohnadfb&entry.994133416=dev&entry.1628503808=vol1&entry.619607554=yes&entry.855480200=vol2&entry.355361513=notes
        //https://docs.google.com/forms/d/e/1FAIpQLSdgirHiERWz5nJxbTWvt8ok4wZfoO04vZDAI847L7M9ABMp4Q/formResponse?usp=pp_url&entry.151339263=mohnad45@gmail.com&entry.214162399=mohnad&entry.121868771=iksal&entry.688748620=0546123203
        // &entry.1031425092=1994-09-21&entry.337248038=facebook&entry.994133416=jobtitle
        // &entry.1628503808=period&entry.619607554=yes&entry.855480200=exp&entry.355361513=notes&submit=Submit
    }

    componentDidMount() {
        try{
            let lang=this.props.location.search;
            lang=lang.substring(lang.indexOf('=')+1,lang.length);
            if(lang=='ar' || lang=='en'){
                if(this.state.lang!=lang){
                    window.location.reload();
                    localStorage.setItem('lang',lang)
                    Translator.handleLang(lang,this.state.context)
                    this.setState({lang:lang})
                }
            }

        }catch (e) {

        }

        this.setState({isSended:false});
        this.setState({errors:{fname:false,email:false,address:false,phone:false,birthdate:false,work:false,volTime:false,volExp:false,resumeFileName:false}});
    }
    onChange = e => {
        const files = Array.from(e.target.files)
        if(e.target.files==null)
            return
        this.setState({resumeFileName:e.target.files[0].name,resumeFile:e.target.files[0]});

    }
    uploadResume(event){
        this.setState({target:event.target})
        let controller=new ServerController();
        let error=false;

        let errors={fname:false,email:false,address:false,phone:false,birthdate:false,work:false,volTime:false,volExp:false,resumeFileName:false};
        this.setState({errors:errors});
        if(this.state.fname==''){
            error=true;
            errors.fname=true;
        }
        if(this.state.email==''){
            error=true;
            errors.email=true;
        }
        if(this.state.phone==''){
            error=true;
            errors.phone=true;
        }
        if(this.state.address==''){
            error=true;
            errors.address=true;
        }
        if(this.state.birthdate==''){
            error=true;
            errors.birthdate=true;
        }
        if(this.state.work==''){
            error=true;
            errors.work=true;
        }
        if(this.state.volTime==''){
            error=true;
            errors.volTime=true;
        }
        if(this.state.volExp==''){
            error=true;
            errors.volExp=true;
        }
        if(this.state.resumeFileName==''){
            error=true;
            errors.resumeFileName=true;
        }
        this.setState({errors:errors});
        if(!error){
            controller.fillVolForm(this.state.resumeFile,this.state.email,this.state.fname,this.state.address,this.state.phone,this.state.birthdate
                ,this.state.work,this.state.volTime,this.state.validPass=="yes"?"1":"0",this.state.volExp,this.state.notes,this.state.lang,this.state.fb);
            this.setState({isSended:true});
        }
        // fillVolForm(,,,,,,,,,,note,lang,fbName){

        //controller.uploadResume(this.state.resumeFile,this.state.email,this.state.fname)
    }
    fillGoogleForm(){
        var url1="https://docs.google.com/forms/d/e/1FAIpQLSdgirHiERWz5nJxbTWvt8ok4wZfoO04vZDAI847L7M9ABMp4Q/formResponse?usp=pp_url";
        var url="&entry.151339263="+this.state.email;
        url=url+"&entry.214162399="+this.state.fname+" "+this.state.lname;
        url=url+"&entry.121868771="+this.state.address;
        url=url+"&entry.688748620="+this.state.phone;
        url=url+"&entry.1031425092="+this.state.birthdate;
        url=url+"&entry.337248038="+this.state.fb;
        url=url+"&entry.994133416="+this.state.work;
        url=url+"&entry.1628503808="+this.state.volTime;
        url=url+"&entry.619607554="+this.state.validPass;
        url=url+"&entry.855480200="+this.state.volExp;
        url=url+"&entry.355361513="+this.state.notes;
        url=url+"&submit=Submit";
        var user_agent = {'Referer':'https://docs.google.com/forms/d/e/1FAIpQLSdgirHiERWz5nJxbTWvt8ok4wZfoO04vZDAI847L7M9ABMp4Q/viewform','User-Agent': "Mozilla/5.0 (X11; Linux i686) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/28.0.1500.52 Safari/537.36"}
        var config = {

            headers: user_agent
        };
        axios.post(url1,url,config).then(function (response) {
            alert(response)
        })
            .catch(function (error) {
                alert(error)
            })
            .finally(function () {
                // always executed
            });
    }
    render() {
        return (
            <div dir={this.state.lang=="ar"?"rtl":"ltr"}>
                <Header page={'volunteer'} context={this}></Header>
                <div id="pageContent">
                    <div id='subPageContent'>
                        <div class='row'>

                        <div className='col-xl-6 col-lg-6 col-sm-12 col-12'>
                            <SectionContainer page={"Volunteer"}/>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-sm-12 col-12 volFormDiv'>
                        <iframe name="hidden_iframe" id="hidden_iframe"></iframe>
                           
                                <div id='Volform'>
                                    
                                    {/*
                                    https://docs.google.com/forms/d/e/1FAIpQLSf_Nx2OHXwiiuQGDOUtuWpBNXIVkYE5jE8CwKmR30hscadW9A/viewform?usp=pp_url
                                    //&entry.1514373676=Full+Name
                                    //&entry.1303970957=E-mail
                                    //&entry.1196187433=Adrress
                                    //&entry.1022073516=phone+number
                                   // &entry.40460485=Birth+Date
                                    //&entry.151338694=Name+on+Facebook
                                    //&entry.1489154772=Work/Education
                                    //&entry.120724928=Volunteering+Time
                                    //&entry.1536939132=Yes
                                    &entry.1736506543=Volunteering+Experience
                                    &entry.1388072990=Notes


                                    https://docs.google.com/forms/d/e/
                                    1FAIpQLSd8TkxvmIFSz-phlE99l5X0P9yBoo_DNbXspjIdR6zYrfSdsg/viewform?usp=pp_url
                                    &entry.1514373676=Full+Name
                                    &entry.1303970957=E-mail
                                    &entry.1196187433=Address
                                    &entry.1022073516=phone+number
                                    &entry.40460485=Birth+Date
                                    &entry.1489154772=Work/Education
                                    &entry.120724928=Volunteering+Time
                                    &entry.1536939132=Yes
                                    &entry.1736506543=Volunteering+Experience
                                    &entry.1388072990=Notes
                                */}
                                  
                                        
                        <form
                        
                         action={this.state.lang=='ar'?"https://docs.google.com/forms/d/e/1FAIpQLSf_Nx2OHXwiiuQGDOUtuWpBNXIVkYE5jE8CwKmR30hscadW9A/formResponse?usp=pp_url":"https://docs.google.com/forms/d/e/1FAIpQLSd8TkxvmIFSz-phlE99l5X0P9yBoo_DNbXspjIdR6zYrfSdsg/formResponse?usp=pp_url"}
                        method="post" target="hidden_iframe" onSubmit="submitted=true;">
                           <div style={{display:this.state.isSended?'none':'block'}}>
                                    <p className={this.state.lang == 'ar' ? 'volFieldTextAr' : 'volFieldText'} style={{color:this.state.errors.fname?'red':'#2B235E'}}>{Translator.getString('Full name', this.state.lang)}</p>
                                <input type='text' name='entry.1514373676' className={ this.state.lang=='ar'?'volForms volFormsAr':'volForms'} id='first' style={{borderColor:this.state.errors.fname?'red':'#1B972B'}}
                                    placeholder={Translator.getString('Full name', this.state.lang) + "*"}
                                    onInvalid="fill this"
                                    onChange={event => this.setState({fname: event.target.value})}>
                                </input>

                                <p className={this.state.lang == 'ar' ? 'volFieldTextAr'  : 'volFieldText'} style={{color:this.state.errors.email?'red':'#2B235E'}}>{Translator.getString('Email', this.state.lang)}</p>
                                <input type='text' name='entry.1303970957' id='mail' className={ this.state.lang=='ar'?'volForms volFormsAr':'volForms'} style={{borderColor:this.state.errors.email?'red':'#1B972B'}}
                                    placeholder={Translator.getString('Email', this.state.lang) + "*"}

                                    onChange={event => this.setState({email: event.target.value})}>
                                </input>
                                <p className={this.state.lang == 'ar' ? 'volFieldTextAr' : 'volFieldText'} style={{color:this.state.errors.address?'red':'#2B235E'}}>{Translator.getString('Address', this.state.lang)}</p>
                                <input type='text' name='entry.1196187433' id='addr' className={ this.state.lang=='ar'?'volForms volFormsAr':'volForms'} style={{borderColor:this.state.errors.address?'red':'#1B972B'}}
                                    placeholder={Translator.getString('Address', this.state.lang) + "*"}

                                    onChange={event => this.setState({address: event.target.value})}>
                                </input>

                                <p className={this.state.lang == 'ar' ? 'volFieldTextAr' : 'volFieldText'} style={{color:this.state.errors.phone?'red':'#2B235E'}} >{Translator.getString('Phone number', this.state.lang)}</p>
                                <input type='number' name='entry.1022073516' id='phone' className={ this.state.lang=='ar'?'volForms volFormsAr':'volForms'} style={{borderColor:this.state.errors.phone?'red':'#1B972B'}}
                                    placeholder={Translator.getString('Phone number', this.state.lang) + "*"}

                                    onChange={event => this.setState({phone: event.target.value})}>
                                </input>


                                {
                                    this.state.lang=='ar'?
                                        <div>
                                            <p className={this.state.lang == 'ar' ? 'volFieldTextAr' : 'volFieldText'}>{Translator.getString('Facebook Name', this.state.lang)}</p>
                                            <input type='text' name='entry.151338694' id='phone' className={ this.state.lang=='ar'?'volForms volFormsAr':'volForms'}
                                                placeholder={Translator.getString('Facebook Name', this.state.lang)}
                                                onChange={event => this.setState({fb: event.target.value})}>
                                            </input>
                                        </div>:<div/>
                                }

                                <p className={this.state.lang == 'ar' ? 'volFieldTextAr' : 'volFieldText'} style={{color:this.state.errors.birthdate?'red':'#2B235E'}}>{Translator.getString('Birthday', this.state.lang)}</p>
                                <input type='date' style={{paddingLeft: 35}} name='entry.40460485' style={{borderColor:this.state.errors.birthdate?'red':'#1B972B'}}
                                    id='birthday' className={ this.state.lang=='ar'?'volForms volFormsAr':'volForms'}
                                    placeholder={Translator.getString('Birthday', this.state.lang) + "*"}
                                    onChange={event => this.setState({birthdate: event.target.value})}>
                                </input>
                                <p className={this.state.lang == 'ar' ? 'volFieldTextAr' : 'volFieldText'} style={{color:this.state.errors.work?'red':'#2B235E'}}>{Translator.getString('Field of work', this.state.lang)}</p>
                                <input type='text' name='entry.1489154772' id='work' className={ this.state.lang=='ar'?'volForms volFormsAr':'volForms'} style={{borderColor:this.state.errors.work?'red':'#1B972B'}}
                                    placeholder={Translator.getString('Field of work', this.state.lang) + "*"}
                                    onChange={event => this.setState({work: event.target.value})}>
                                </input>
                                <p className={this.state.lang == 'ar' ? 'volFieldTextAr' : 'volFieldText'} style={{color:this.state.errors.volTime?'red':'#2B235E'}}>{Translator.getString('TIME OF VOLUNTEERING (ex. in april/winter)', this.state.lang)}</p>
                                <input type='text' name='entry.120724928' id='fb' className={ this.state.lang=='ar'?'volForms volFormsAr':'volForms'}  style={{borderColor:this.state.errors.volTime?'red':'#1B972B'}}
                                    placeholder={Translator.getString('TIME OF VOLUNTEERING (ex. in april/winter)', this.state.lang) + "*"}
                                    onChange={event => this.setState({volTime: event.target.value})}>
                                </input>
                                <div className='volFormsCheck' style={{display:'flex',flexDirection:'column',justifyContent:'flex-end'}}>
                                    <p style={{textAlign:this.state.lang=='ar'?'right':'left'}}>{Translator.getString('Do you have a valid passport for at least 6 months', this.state.lang) + "*"}</p>
                                    <div className="form-check  formcheck" style={{width:30,marginRight:25}}>
                                        <label className="form-check-label " style={{fontSize: '16px'}}
                                            htmlFor="radio1">
                                            <input type="radio" className="form-check-input" id="radio1"
                                                name="entry.1536939132"
                                                value="yes"
                                                onChange={event => this.setState({validPass: event.target.value})}
                                                checked={this.state.validPass === 'yes'}/>{Translator.getString('Yes', this.state.lang)}
                                        </label>
                                    </div>
                                    <div className="form-check formcheck" style={{width:30,marginRight:23}}>
                                        <label className="form-check-label " style={{fontSize: '16px'}}
                                            htmlFor="radio2">
                                            <input type="radio" className="form-check-input" id="radio2"
                                                name="entry.1536939132"
                                                value="no"
                                                onChange={event => this.setState({validPass: event.target.value})}
                                                checked={this.state.validPass === 'no'}/>{Translator.getString('No', this.state.lang)}
                                        </label>
                                    </div>
                                </div>
                                <p style={{marginTop: '40px'}}
                                className={this.state.lang == 'ar' ? 'volFieldTextAr' : 'volFieldText'} style={{color:this.state.errors.volExp?'red':'#2B235E'}}>{Translator.getString('tell us about your volunteering experience', this.state.lang)}</p>
                                <textarea type='textarea' name='entry.1736506543' id='fb' className={ this.state.lang=='ar'?'volForms volFormsAr':'volForms'}
                                        placeholder={Translator.getString('tell us about your volunteering experience', this.state.lang) + "*"}
                                        style={{borderColor:this.state.errors.volExp?'red':'#1B972B'}}

                                        onChange={event => this.setState({volExp: event.target.value})}>
                                            </textarea>
                                <p className={this.state.lang == 'ar' ? 'volFieldTextAr' : 'volFieldText'}>{Translator.getString('notes', this.state.lang)}</p>
                                <textarea style={{marginTop: 0, paddingTop: 0}} type='textarea'
                                        name='entry.1388072990' id='fb' className={ this.state.lang=='ar'?'volForms volFormsAr':'volForms'}
                                        placeholder={Translator.getString('notes', this.state.lang)}
                                        onChange={event => this.setState({notes: event.target.value})}>
                                            </textarea>
                                <input type='file' id='resumeUploader' className='hiddenUploader'
                                    onChange={this.onChange}/>
                                <label htmlFor="resumeUploader" style={{color:this.state.errors.resumeFileName?'red':'#fff',borderColor:this.state.errors.resumeFileName?'red':'#fff'}} id='imageInputLabel'>{this.state.lang=='ar'?'إرفاق ملف سيرة ذاتية*':'upload CV file*'}</label>
                                <p id='resumeUploaderFileName'>{this.state.resumeFileName}</p>
                                <button id='submitBtn' type="submit"
                                        onClick={(event) => this.uploadResume(event)}>{Translator.getString('submit', this.state.lang)} </button>
                            </div>
                            <div style={{marginTop:30,maginBottom:30,display:this.state.isSended?'block':'none'}}>
                                {
                                    this.state.isSended?<Lottie
                                    width={350}
                                    height={350}
                                    options={{
                                        animationData: animation,
                                        loop: false,

                                    }}
                                />:<div/>
                                }
                            <h2 className={'volSuccessMSG'}>{Translator.getString('volThanx', this.state.lang)}</h2>
                        </div>
                       
                        <br/><br/>
                    </form>
                                  
                                    

                                </div>
                            

                        </div>
                    </div>
                    </div>
                </div>
                <Footer lang={this.state.lang}/>
            </div>
        )
    }
}
// /onClick={(event)=>this.uploadResume(event)}
