import React,{Component} from 'react'

import Header from "../../components/Header";

import Footer from "../../components/Footer";
import ServerController from "../../utilities/ServerController";
import Layout1 from "../../components/Layouts/Layout1";
import Layout2 from "../../components/Layouts/Layout2";
import Layout3 from "../../components/Layouts/Layout3";
import Layout4 from "../../components/Layouts/Layout4";
import SectionContainer from "../../components/SectionContainer";
import NewLayout from "../../components/Layouts/NewsLayout";
import Modal from "react-bootstrap/Modal";
import TextEllipsis from "react-text-ellipsis";
//MediaAndArticles
export default class MediaAndArticlesPage extends Component {
    constructor() {
        super();
        this.state={isBottom:false,tabToShow:null,show:false,data:null,lang:localStorage.getItem('lang'),sectionToDeleteId:null,sections:null,sectionToEdit:null,page:null,hideConfimBtn:false,success:false,show1:false,show2:false,show3:false,show4:false,showDelete:false}
        ///this.state = {sections:null,lang:localStorage.getItem('lang')};
    }

    componentDidMount() {
        //document.addEventListener('scroll', this.trackScrolling);
        let controller=new ServerController();

        controller.getSections(this.handleData,this,'MediaAndArticles')
    }
    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }
    trackScrolling = () => {
        const wrappedElement = document.getElementById('pageContent');
        if (this.isBottom(wrappedElement)) {
            this.setState({isBottom:true});
        }else{
            this.setState({isBottom:false});
        }
    };
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
    }

    componentDidUpdate() {

    }
    handleData(data,context){
        for(let i=0;i<data.length;i++){
            data[i].index=i;
        }
        context.setState({sections:data});
    }
    goToSectionPage(section){
        this.setState({tabToShow:section,show:true})
    }
    getSections(){
        if(this.state.sections!=null){
            let secArray=[];
            for(let i=0;i<this.state.sections.length;i+=1){
                secArray.push(
                    <div  style={{width:'75%'}} dir={this.state.lang=="ar"?"rtl":"ltr"} className={'row newsPagesItem'}  onClick={()=>this.goToSectionPage(this.state.sections[i])}>
                        <NewLayout section={this.state.sections[i]} />
                    </div>

                )

            }

            return(
                <div>
                    {secArray}
                </div>

            )
        }

    }
    getSection(){
        if(this.state.tabToShow!=null){


            if(this.state.tabToShow.layoutType==1){
                return (<Layout1 section={this.state.tabToShow} />)
            }
            if(this.state.tabToShow.layoutType==2){
                return (<Layout2 section={this.state.tabToShow} />)
            }
            if(this.state.tabToShow.layoutType==3){
                return (<Layout3 section={this.state.tabToShow}  />)
            }
            if(this.state.tabToShow.layoutType==4){
                return(<Layout4 section={this.state.tabToShow}  />)
            }

        }

    }
    scrollToNew(index){
        window.scrollTo({top:index*450})
    }
    render() {
        return (
            <div dir={this.state.lang=="ar"?"rtl":"ltr"}>
                <Header page={'news'}  context={this}></Header>
                <div id="pageContent"  >
                    <div id='subPageContent' >
                        <div className={'row'} >
                            <div className={'col-xl-4 col-lg-4 col-sm-12 col-12'} className={'sideBar'}>
                                <div style={{backgroundColor:'#2B235E',height:this.state.isBottom?'70%':'100%',width:'25%',position:'fixed',top:0,paddingTop:180}} className={this.state.lang=="ar"?"sideTitlesHeaderDivAr":"sideTitlesHeaderDiv"}>
                                    <p style={{textAlign:this.state.lang=='ar'?'right':'left'}} className={'sideTitlesHeader'}>{this.state.lang=='ar'?'عناوين':'articles'}</p>
                                    <div class={'newsMenu'} style={{height:'90%',overflow:'scroll'}}>

                                        {
                                            this.state.sections&&this.state.sections.map(s=>{
                                                return(<TextEllipsis
                                                    lines={5}
                                                    ellipsisChars={'...'}
                                                    tagClass={'className'}
                                                    debounceTimeoutOnResize={0}
                                                    useJsOnly={true}
                                                    onResult={(result) => {
                                                        if (result === TextEllipsis.RESULT.TRUNCATED)
                                                            console.log('text get truncated');
                                                        else
                                                            console.log('text does not get truncated');
                                                    }}>
                                                    <div onClick={()=>this.scrollToNew(s.index)} className={this.state.lang=="ar"?'ql-editor sideTitlesAr':'ql-editor sideTitles'}  style={{color:'#fff'}} dangerouslySetInnerHTML={{__html: this.state.lang=="ar"?s.titleAr:s.title}}></div>

                                                </TextEllipsis>)


                                            })
                                        }
                                    </div>


                                </div>
                            </div>
                            <div className={'col-xl-8 col-lg-8 col-sm-12 col-12'} id={'artDiv'} className={this.state.lang=="ar"?'articalsDivAr':'articalsDiv'} >
                                {this.getSections()}
                            </div>
                        </div>
                    </div>
                    <Modal show={this.state.show  } onHide={()=>{this.setState({show:false})}} id={'articalModal'} dir={this.state.lang=="ar"?"rtl":"ltr"} >
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            {this.getSection()}
                        </Modal.Body>

                    </Modal>
                </div>
                <div  className={'footerCover'}>

                </div>

            </div>
        )
    }
}
