import React,{Component} from 'react'
import '../App.css';

import testNewsImg from '../imgs/testnews.jpg'
import nextIcon from '../imgs/nextIcon@2x.png'
import backIcon from '../imgs/backIcon@2x.png'
import ServerController from "../utilities/ServerController";
import Modal from "react-bootstrap/Modal";
import Slider from "react-slick";
import SectionContainer from "./SectionContainer";
import Layout1 from "./Layouts/Layout1";
import Layout2 from "./Layouts/Layout2";
import Layout3 from "./Layouts/Layout3";
import Layout4 from "./Layouts/Layout4";
import TextEllipsis from "react-text-ellipsis";
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", borderRadius:200,background: "green" }}
            onClick={onClick}
        >

        </div>
    );
}
export default class HomeNewsBanner extends Component {
    constructor(props) {
        super(props);
        var settings = {
            className: "centerNewImg",
            centerMode: true,
            infinite: true,
            centerPadding: "60px",
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500,
            arrows:true,
        };
        this.state={news:null,lang:localStorage.getItem('lang'),show:false,tabToShow:null,hide:false,hideCallBack:null,context:null,sliderSettings:settings,pageX:-1,pageY:-1}
    }
    componentDidMount() {
        this.setState({hideCallBack: this.props.hideCallBack,context:this.props.context})
        let controller=new ServerController(this);
        controller.getHomeNews(this.handleData,this);
    }
    componentDidUpdate(prevProps) {
        if (this.props.hideCallBack != prevProps.hideCallBack) {
            this.setState({hideCallBack: this.props.hideCallBack})
        }
        if (this.props.context != prevProps.context) {
            this.setState({context: this.props.context})
        }
    }
    handleData(data,context){
        let hide=false;
        if(data==null || data.length==0) {
            hide = true;
            if(context.state.hideCallBack!=null){
                context.state.hideCallBack(true,context.state.context);
            }

        }
        context.setState({news:data,hide:hide})
    }
    getXy(event){
        this.setState({pageX:event.pageX,pageY:event.pageY})
    }
    openModal(event,i){
        if(this.state.pageX==event.pageX && this.state.pageY==event.pageY)
        {
            this.setState({tabToShow:this.state.news[i],show:true,pageX:-1,pageY:-1})
        }
    }
    getNews(){
        if(this.state.news!=null){
            let newsArray=[];
            for(let i=0;i<this.state.news.length;i++){
                newsArray.push(<div onPointerDown={(event)=>this.getXy(event)} onPointerUp={(event)=>this.openModal(event,i)} class={'newsSlideItem'}>
                    <img src={ServerController.UPLOADS+this.state.news[i].img} className='newsImage objectFitNews' style={{borderRadius:'5px'}}/>
                    <TextEllipsis
                        lines={2}
                        ellipsisChars={'...'}
                        tagClass={'className'}
                        debounceTimeoutOnResize={0}
                        useJsOnly={true}
                        onResult={(result) => {
                            if (result === TextEllipsis.RESULT.TRUNCATED)
                                console.log('text get truncated');
                            else
                                console.log('text does not get truncated');
                        }}>
                        <div className={'homeNewsText'}
                             dangerouslySetInnerHTML={{__html: this.state.lang=='ar'?this.state.news[i].titleAr:this.state.news[i].title}}></div>
                    </TextEllipsis>

                </div>)
            }
            return(

                <Slider {...this.state.sliderSettings}>
                    {newsArray}
                </Slider>
            );
        }

    }
    getModalImg(){
        if(this.state.tabToShow==null)
            return '';
        return this.state.tabToShow.img;
    }
    getModalText(){
        if(this.state.tabToShow==null)
            return '';
        return this.state.lang=='ar'?this.state.tabToShow.textAr:this.state.tabToShow.text
    }
    getSection(){
        if(this.state.tabToShow!=null){


                if(this.state.tabToShow.layoutType==1){
                    return (<Layout1 section={this.state.tabToShow} />)
                }
                if(this.state.tabToShow.layoutType==2){
                    return (<Layout2 section={this.state.tabToShow} />)
                }
                if(this.state.tabToShow.layoutType==3){
                    return (<Layout3 section={this.state.tabToShow}  />)
                }
                if(this.state.tabToShow.layoutType==4){
                    return(<Layout4 section={this.state.tabToShow}  />)
                }

        }

    }
    render() {

        return (
            <div id="HomeNewBannerDiv" dir={'ltr'} class={this.state.hide==true?"d-none":""}>

                    {this.getNews()}

                <Modal show={this.state.show  } onHide={()=>{this.setState({show:false})}} id={'tabDetailsModal'} dir={this.state.lang=="ar"?"rtl":"ltr"} >
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {this.getSection()}
                    </Modal.Body>

                </Modal>
            </div>
        )
    }


}
/*
<img src={this.state.news[i].img} className='newsImage'/>

<div id="demo" className="carousel slide newsCarousel"  data-ride="carousel">
                        {this.getNews()}
                    <a className="carousel-control-prev" href="#demo" data-slide="prev" style={{height: '28px'}}>
                        <img src={backIcon}/>
                    </a>
                    <a className="carousel-control-next" href="#demo" data-slide="next" style={{height: '28px'}}>
                        <img src={nextIcon}/>
                    </a>

                </div>
 */
