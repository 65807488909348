import React,{Component} from 'react'

import Header from "../../components/Header";

import Footer from "../../components/Footer";
import ServerController from "../../utilities/ServerController";
import Layout1 from "../../components/Layouts/Layout1";
import Layout2 from "../../components/Layouts/Layout2";
import Layout3 from "../../components/Layouts/Layout3";
import Layout4 from "../../components/Layouts/Layout4";
import SectionContainer from '../../components/SectionContainer';

import ExampleComponent from "react-rounded-image";
export default class OurPartners extends Component {
    constructor() {
        super();
        this.state = {donors:null,lang:localStorage.getItem('lang')};
    }

    componentDidMount() {
        let controller=new ServerController();
        controller.getDonors(this.handleData,this);
    }
    handleData(data,context){
        context.setState({donors:data})
    }
    getDonors(){
        let items=[];
        if(this.state.donors==null)
            return
        for(let i=0;i<this.state.donors.length;i++){
            if(this.state.donors[i].link!='' && this.state.donors[i].link!=null && this.state.donors[i].link.length>1 ){
                items.push(<div className='teamItem col-xl-3 col-lg-4  col-sm-6  col-6'>
                    <a href={this.state.donors[i].link} target="_blank">
                        <img src={ServerController.UPLOADS+this.state.donors[i].img}/>
                        <h6>{this.state.lang=='ar'?this.state.donors[i].nameAr:this.state.donors[i].name}</h6>
                    </a>
                </div>)
            }else{
                items.push(<div className='teamItem col-xl-3 col-lg-4  col-sm-6  col-6'>

                        <img src={ServerController.UPLOADS+this.state.donors[i].img}/>
                        <h6>{this.state.lang=='ar'?this.state.donors[i].nameAr:this.state.donors[i].name}</h6>

                </div>)
            }
        }
        return(<div id='tabsInner' className='row'> {items}</div>)
    }


    render() {
        return (
            <div dir={this.state.lang=="ar"?"rtl":"ltr"}>
                <Header page={'whoweare'}  context={this}></Header>
                <div id="pageContent">
                    <div id='subPageContent'>
                        <SectionContainer page={'Donors'}/><br/>
                        {this.getDonors()}
                    </div>
                </div>
                <div  className={'footerCover'}>

                </div>
                <Footer lang={this.state.lang} />
            </div>
        )
    }
}
