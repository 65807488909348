import React,{Component} from 'react'
import { Fade } from 'react-slideshow-image';
import '../App.css';
import  im1 from '../imgs/im1.jpg'
import  im2 from '../imgs/im2.JPG'
import  im3 from '../imgs/im3.jpg'
import ServerController from "../utilities/ServerController";
export default class HomeSlider extends Component {
    constructor(props) {
        super(props);
        const slideImages = [
            im1,
            im2,
            im3
        ];

        const properties = {
            duration: 4000,
            transitionDuration: 500,
            infinite: true,
            indicators: false,
            arrows: true,
            onChange: (oldIndex, newIndex) => {
                console.log(`slide transition from ${oldIndex} to ${newIndex}`);
            }
        }
        this.state={slideProps:properties,imgs:null,lang:localStorage.getItem('lang')}
    }
    componentDidMount() {
        let controller=new ServerController(this);
        controller.getHomeSlider(this.handleData,this);
    }
    handleData(data,context){
        context.setState({imgs:data})
    }
    getImages(){
        let items=[];
        if(this.state.imgs==null){
            items.push(<div className="each-fade">
                <div className="image-container">
                    <img id='volImg' src={''} />

                </div>
            </div>)
            return items
        }
        for(let i=0;i<this.state.imgs.length;i++){
            items.push(<div className="each-fade">
                <div className="image-container" style={{position:'relative'}}>
                    <img id='volImg' src={ServerController.UPLOADS+this.state.imgs[i].img} />
                    <div className='ql-editor sliderImgTxt'
                         dangerouslySetInnerHTML={{__html: this.state.lang=='ar'?this.state.imgs[i].textAr:this.state.imgs[i].text}}></div>
                </div>
            </div>)
        }
        return items
    }
    render() {
        return (
            <div className="slide-container" style={{direction:'initial'}}>
                <Fade {...this.state.slideProps}>
                    {this.getImages()}
                </Fade>
            </div>
        )
    }
}
