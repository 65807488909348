export default class Translator {
    static getString(key,lang){

        switch (lang) {
            case 'en':
                return this.getEnglishString(key);
                break;
            case 'ar':
                return this.getArabicString(key);
                break;

        }
    }
    static getEnglishString(key){
        switch (key) {
            case 'HOME':return 'HOME'
            case 'WHO WE ARE':return 'WHO WE ARE'
            case 'CONTACT':return 'CONTACT'
            case 'MISSIONS & VISION':return  ' OUR VISION & MISSION'
            case 'OUR STORY':return 'OUR STORY'
            case 'OUR TEAM':return 'TEAM'
            case 'OUR VOLUNTEERS':return 'OUR VOLUNTEERS'
            case 'BOARD MEMBERS':return 'BOARD MEMBERS'
            case 'OUR PARTNERS':return 'PARTNERS'
            case 'WHAT WE DO':return 'WHAT WE DO'
            case 'OUR MODEL':return 'OUR MODEL'
            case 'WHERE WE WORK':return 'WHERE WE WORK'
            case 'COMMUNITY':return 'COMMUNITY'
            case 'PROGRAMS':return 'PROGRAMS'
            case 'NEWS & EVENTS':return 'NEWS & EVENTS'
            case 'MISSIONS':return 'MISSIONS'
            case 'GALLERY':return 'GALLERY'
            case 'WORKSHOPS':return 'WORKSHOPS'
            case 'CONFERENCES':return 'CONFERENCES'
            case 'ARTICLES':return 'ARTICLES'
            case 'DONATE':return 'DONATE'
            case 'VOLUNTEER':return 'VOLUNTEER'
            case 'MISSION':return 'MISSION'
            case 'Fun facts about Seeds':return 'Fun facts about Seeds'
            case 'Volunteers':return 'Volunteers'
            case 'Countries they come from':return 'Countries we work in'
            case 'Hours of volunteering':return 'Hours of volunteering'
            case 'Years':return 'Years'
            case 'seeds of humanity':return 'Seeds of Humanity'
            case 'About us':return 'About us'
            case 'Contact':return 'Contact'
            case 'Facebook':return 'Facebook'
            case 'Twitter':return 'Twitter'
            case 'Instagram':return 'Instagram'
            case 'Youtube':return 'Youtube'
            case 'Donate':return 'Donate'
            case 'Donate Now':return 'Donate Now'
            case 'Tamra , palestine':return 'Baqa al-Gharbiyye , Alkuds 574 , Post Box:5050 , Zip:3010000 , palestine'
            case 'baqafooter': return 'Baqa al-Gharbiyye , palestine'
            case 'GREECE':return 'GREECE'
            case '167, Konstantinoupoleos av , TK 10441, Athens, Greece':return '167, Konstantinoupoleos av , TK 10441, Athens, Greece'
            case 'PALESTINE':return 'PALESTINE'
            case 'First Name':return 'First Name'
            case 'last Name':return 'last Name'
            case 'Phone number':return 'Phone number'
            case 'Email':return 'Email'
            case 'Your question or query':return 'Your question or query'
            case 'Send':return 'Send'
            case 'DONATE AMOUNT':return 'DONATE AMOUNT'
            case 'RECEIPT DETAILS':return 'RECEIPT DETAILS'
            case 'DONATION METHOD':return 'DONATION METHOD'
            case 'Credit Card':return 'Credit Card';
            case 'PayPal':return 'PayPal';
            case 'Direct debit':return 'Direct debit';
            case 'Cash':return 'Cash';
            case 'Card Owner Name':return  'Card Owner Name';
            case 'Credit Card Number':return  'Credit Card Number';
            case 'Expiration Year':return  'Expiration Year';
            case 'Expiration Month':return  'Expiration Month';
            case 'Security Digits (CVV)':return  'Security Digits (CVV)';
            case 'Donate Once':return 'Donate Once'
            case 'Full name':return 'Full name'
            case 'Address':return 'Address'
            case 'Birthday':return 'Birthday'
            case 'Facebook Name':return 'Facebook Name'
            case 'Field of work':return 'Field of work'
            case 'TIME OF VOLUNTEERING (ex. in april/winter)':return 'When do you want to volunteer? (which month/dates)'
            case 'Do you have a valid passport for at least 6 months':return 'Do you have a valid passport for at least 6 months'
            case 'Yes':return 'Yes'
            case 'No':return 'No'
            case 'tell us about your volunteering experience':return 'Tell us about your volunteering experience (if you have)'
            case 'notes':return 'Notes'
            case 'submit':return 'submit'
            case 'contactThanx': return 'thanks for contacting seeds,we will contact you as soon as possible'
            case 'volThanx': return 'thanks you for volunteering ,we will contact you as soon as possible';
            case 'MEDIA & ARTICLES': return 'MEDIA & ARTICLES'
            case 'DONATE_THANKS':return 'Thank you for donating to us'
            case 'DONATE_AMOUNT':return 'Amount of the donation'
            case 'EID SACRIFICES': return 'EID SACRIFICES'
            
        }
    }
    static getArabicString(key){
        switch (key) {
            case 'HOME':return 'الصفحة الرئيسية'
            case 'WHO WE ARE':return 'من نحن'
            case 'MISSIONS & VISION':return  'المهام والرؤية'
            case 'CONTACT':return 'اتصل بنا'
            case 'MISSONS & VISION':return  'المهام والأهداف'
            case 'OUR STORY':return 'قصتنا'
            case 'OUR TEAM':return 'فريق العمل'
            case 'OUR VOLUNTEERS':return 'المتطوعون لدينا'
            case 'BOARD MEMBERS':return 'أعضاء المجلس'
            case 'OUR PARTNERS':return 'شركاؤنا'
            case 'WHAT WE DO':return 'ما نفعله'
            case 'OUR MODEL':return 'نموذجنا'
            case 'WHERE WE WORK':return 'أين نعمل'
            case 'COMMUNITY':return 'تواصل اجتماعي'
            case 'PROGRAMS':return 'برامج'
            case 'NEWS & EVENTS':return 'الأخبار و الأحداث'
            case 'MEDIA':return 'تغطية إعلامية'
            case 'MISSIONS':return 'البعثات'
            case 'WORKSHOPS':return 'ورش عمل'
            case 'CONFERENCES':return 'المؤتمرات'
            case 'ARTICLES':return 'مقالات'
            case 'DONATE':return 'تبرع'
            case 'VOLUNTEER':return 'تطوع'
            case 'MISSION':return 'مهمتنا'
            case 'Fun facts about Seeds':return 'حقائق ممتعة عن البذور'
            case 'Volunteers':return 'متطوع'
            case 'Countries they come from':return 'دولة'
            case 'Hours of volunteering':return 'ساعة من العمل التطوعي'
            case 'Years':return  'سنوات'
            case 'seeds of humanity':return 'بذور من اجل الإنسانية'
            case 'About us':return 'معلومات عنا'
            case 'Contact':return 'اتصل بنا'
            case 'Facebook':return 'فيس بوك'
            case 'Twitter':return 'تويتر'
            case 'Instagram':return 'إينستاجرام'
            case 'Youtube':return 'يوتيوب'
            case 'Donate':return 'تبرع'
            case 'Donate Now':return 'تبرع الآن'
            case 'Tamra , palestine':return ' باقة الغربية , فلسطين ,شارع القدس 547 , صندوق البريد:5050 , الرمز البريدي:3010000 '
            case 'baqafooter': return ' باقة الغربية ، فلسطين'
            case 'GREECE':return 'اليونان'
            case '167, Konstantinoupoleos av , TK 10441, Athens, Greece':return '167, Konstantinoupoleos av , TK 10441, Athens, Greece'
            case 'PALESTINE':return 'فلسطين'
            case 'First Name':return 'الاسم الشخصي'
            case 'last Name':return 'اسم العائلة'
            case 'Phone number':return 'رقم الهاتف'
            case 'Email':return 'البريد الإلكتروني'
            case 'Your question or query':return 'اكتب سؤالك أو استعلامك'
            case 'Send':return 'إرسال'
            case 'DONATE AMOUNT':return 'مبلغ التبرع'
            case 'Donate Once':return 'تبرع لمره واحده'
            case 'Donate Once Text':return 'تبرع للجمعيه عن طريق دفعه واحده فقط'
            case 'RECEIPT DETAILS':return 'تفاصيل الفاتورة'
            case 'DONATION METHOD':return 'طريقة التبرع'
            case 'Credit Card':return 'بطاقة ائتمان';
            case 'PayPal':return 'بأي بال';
            case 'Direct debit':return 'التبرع الشهري ( صدقه جاريه )';
            case 'Direct debit Text':return 'تبرع بشكل شهري للجمعيه ( صدقه جاريه ) سوف يتم دفع بخصم شهري من حساب بطاقه الائتمان التي تسعتمل في التبرع'
            case 'Cash':return 'دفع نقدا';
            case 'Card Owner Name':return  'اسم مالك البطاقة';
            case 'Credit Card Number':return  'رقم بطاقه الائتمان';
            case 'Expiration Year':return  'سنة انتهاء صلاحية البطاقة';
            case 'Expiration Month':return  'شهر انتهاء صلاحية البطاقة';
            case 'Security Digits (CVV)':return  'أرقام الأمان (CVV)';

            case 'Full name':return 'الاسم الكامل'
            case 'Address':return 'العنوان'
            case 'Birthday':return 'تاريخ الميلاد'
            case 'Facebook Name':return 'الإسم على الفيسبوك (إن وجد)'
            case 'Field of work':return 'مجال العمل/التعليم'
            case 'TIME OF VOLUNTEERING (ex. in april/winter)':return 'الفترة الملائمة للتطوع (مثال: فترة الشتاء/حملات رمضان/ عطلة الربيع الخ ...)'
            case 'Do you have a valid passport for at least 6 months':return 'هل لديك جواز سفر ساري المفعول لمدة 6 أشهر على الأقل'
            case 'Yes':return 'نعم'
            case 'No':return 'لا'
            case 'tell us about your volunteering experience':return 'هل لديك تجربة سابقة في التطوع؟ اخبرنا عنها ..'
            case 'notes':return 'ملاحظات اضافية'
            case 'submit':return 'تقديم'
            case 'contactThanx': return 'شكرا على اتصالك بجمعيه بذور ، سوف نتصل بك في أقرب وقت ممكن'
            case 'volThanx': return 'شكرا لك على تقديم طلب التطوع، سنتواصل مع مقدميه الطلبات لاحقًا حسب الحاجة.';
            case 'GALLERY':return 'معرض الصور'
            case 'MEDIA & ARTICLES': return 'وسائل الإعلام والمقالات'
            case 'DONATE_THANKS':return 'شكرا لك على التبرع'
            case 'DONATE_AMOUNT':return 'مبلغ التبرع'
            case 'EID SACRIFICES': return 'الأضاحي'

        }
    }
    static handleLang(lang,context){
        localStorage.setItem('lang',lang)
        context.setState({lang:lang})
    }

}
