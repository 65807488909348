import React,{Component} from 'react'
import '../App.css';

import testNewsImg from '../imgs/testnews.jpg'
import nextIcon from '../imgs/nextIcon@2x.png'
import backIcon from '../imgs/backIcon@2x.png'
import ServerController from "../utilities/ServerController";
import Modal from 'react-bootstrap/Modal';
import Translator from "../utilities/Translator";
import NewLayout from "./Layouts/NewsLayout";
import Layout1 from "./Layouts/Layout1";
import Layout2 from "./Layouts/Layout2";
import Layout3 from "./Layouts/Layout3";
import Layout4 from "./Layouts/Layout4";
import {TextFocus} from 'react-text-effects'
export default class HomeRoundedTabs extends Component {
    constructor() {
        super();
        this.state={tabIndex:0,sections:null,showSection:false,showArticls:false,data:null,lang:localStorage.getItem('lang'),show:false,tabToShow:null}
    }
    componentDidMount() {
        this.setState({lang: this.props.lang})
        let controller=new ServerController();
        controller.getHomeRoundedTabs(this.handleData,this,this.state.lang);

        controller.getSections(this.handleSectionsData,this,'Articles')
    }
    componentDidUpdate(prevProps) {
        if(this.state.lang!=prevProps.lang)
            this.setState({lang:prevProps.lang})
    }
    handleSectionsData(data,context){
        context.setState({sections:data});
    }
    handleData(data,context){
        context.setState({data:data});
    }
    tabHover(index){
        let data=this.state.data;
        for(let i=0;i<data.length;i++){
            if(i==index){
                data[i].selected=true;
            }else{
                data[i].selected=false;
            }
        }
        this.setState({data:data});
    }
    getRounedTabs(){
        let items=[];
        if(this.state.data==null)
            return
        for(let i=0;i<this.state.data.length;i++){

            items.push(<div onMouseEnter={()=>this.tabHover(i)} onMouseLeave={()=>this.tabHover(-1)} className={this.props.isMobile?'  col-xl-4 col-lg-4 col-sm-6 col-6 RectabItemMobile':'RectabItem  col-xl-4 col-lg-4 col-sm-6 '} onClick={()=>{
                this.setState({showArticls:false,show:true,tabIndex:i,tabToShow:this.state.data[i]});
                window.scrollTo({top:this.props.isProgramsPage==true?800:2000})
               // window.scrollTo({})
            }}>

                <img src={ServerController.UPLOADS+this.state.data[i].img}/>
                <p>{this.state.lang=='ar'?this.state.data[i].nameAr:this.state.data[i].name}</p>
                <div style={{width:'100%',height:'100%',position:'absolute',top:0,display:this.state.data[i].selected==true?'flex':'none',justifyContent:'center',alignItems:'center'}}>
                    <TextFocus type='in' duration='1s' >
                        <h6 className={'learnMoreText'}>+ Learn more</h6>
                    </TextFocus>
                </div>
            </div>)
        }
        items.push(<div onMouseEnter={()=>this.setState({programsHover:true})} onMouseLeave={()=>this.setState({programsHover:false})}className={this.props.isMobile?'  col-xl-4 col-lg-4 col-sm-6 col-6 RectabItemMobile':'RectabItem  col-xl-4 col-lg-4 col-sm-6 '} onClick={()=>{
            this.setState({show:false,showArticls:true})
            window.scrollTo({top:this.props.isProgramsPage==true?800:2000})
        }}>

            <img src={require('../imgs/mpic.jpg')}/>
            <p>{Translator.getString('MISSION',this.state.lang)}</p>
            <div style={{width:'100%',height:'100%',position:'absolute',top:0,display:this.state.programsHover==true?'flex':'none',justifyContent:'center',alignItems:'center'}}>
                <TextFocus type='in' duration='1s' >
                    <h6 className={'learnMoreText'}>+ Learn more</h6>
                </TextFocus>
            </div>
        </div>)
        return(<div id='tabsInner' className={this.props.isMobile?'row RectabItemRow':'row'} > {items}</div>)
    }
    getModalTitle(){
        if(this.state.tabToShow==null)
            return '';
        return this.state.lang=='ar'?this.state.tabToShow.nameAr:this.state.tabToShow.name
    }
    getModalImg(){
        if(this.state.tabToShow==null)
            return '';
        return ServerController.UPLOADS+this.state.tabToShow.img;
    }
    getModalText(){
        if(this.state.tabToShow==null)
            return '';
        return this.state.lang=='ar'?this.state.tabToShow.textAr:this.state.tabToShow.text
    }
    goToSectionPage(section){
        this.setState({tabToShow:section,showSection:true})
    }
    getSections(){
        if(this.state.sections!=null){
            let secArray=[];
            for(let i=0;i<this.state.sections.length;i+=2){
                if(this.state.sections.length%2==1 && i==this.state.sections.length-1){
                    secArray.push(
                        <div className='row' style={{
                            paddingBottom: 20,
                            direction:this.state.lang=="ar"?"rtl":"ltr"
                        }}>
                            <div className='col-xl-5 col-lg-5 col-sm-12 col-12' style={{cursor:'pointer'}} onClick={()=>this.goToSectionPage(this.state.sections[i])}>
                                <NewLayout page={"missions"} section={this.state.sections[i]}/>
                            </div>

                        </div>
                    )
                }else{
                    secArray.push(
                        <div  dir={this.state.lang=="ar"?"rtl":"ltr"} className={'row newsPagesItem'}  >
                            <div className='col-xl-6 col-lg-6 col-sm-12 col-12' style={{cursor:'pointer'}} onClick={()=>this.goToSectionPage(this.state.sections[i])}>
                                <NewLayout page={"missions"} section={this.state.sections[i]} />
                            </div>
                            <div className='col-xl-6 col-lg-6 col-sm-12 col-12'  style={{cursor:'pointer'}} onClick={()=>this.goToSectionPage(this.state.sections[i+1])} >
                                <NewLayout page={"missions"} section={this.state.sections[i+1]} />
                            </div>
                        </div>

                    )
                }

            }

            return(
                <div>
                    {secArray}
                </div>

            )
        }

    }
    getSection(){
        if(this.state.tabToShow!=null){


            if(this.state.tabToShow.layoutType==1){
                return (<Layout1 section={this.state.tabToShow} />)
            }
            if(this.state.tabToShow.layoutType==2){
                return (<Layout2 section={this.state.tabToShow} />)
            }
            if(this.state.tabToShow.layoutType==3){
                return (<Layout3 section={this.state.tabToShow}  />)
            }
            if(this.state.tabToShow.layoutType==4){
                return(<Layout4 section={this.state.tabToShow}  />)
            }

        }

    }
    incTab(){
        let tabIndex=this.state.tabIndex+1;
        if(tabIndex<this.state.data.length){
            this.setState({tabToShow:this.state.data[tabIndex],tabIndex:tabIndex})
        }else{
            this.setState({showArticls:true,show:false})
        }
    }
    decTab(){
        let tabIndex=this.state.tabIndex-1;
        if(tabIndex>=0){
            this.setState({tabToShow:this.state.data[tabIndex],tabIndex:tabIndex})
        }else{
            this.setState({showArticls:true,show:false})
        }
    }
    render() {
        return (
            <div id="HomeRoundedTabsDiv" className={this.props.isMobile?'HomeRoundedTabsDivMobile':''} >
                {this.getRounedTabs()}
                {/*<Modal show={this.state.show  } onHide={()=>{this.setState({show:false})}} id={'tabDetailsModal'} dir={this.state.lang=="ar"?"rtl":"ltr"} >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.getModalTitle()}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <img src={this.getModalImg()} style={{width:'90%',margin:"auto",display:'block',height:'600px',objectFit:'cover'}}/><br/>
                        <div style={{textAlign:'center',width:'auto'}} class='ql-editor' dangerouslySetInnerHTML={{__html: this.getModalText()}}></div>

                    </Modal.Body>

                </Modal>*/}
                <div style={{display:this.state.show?'flex':'none',flexDirection:'column',width:this.props.isMobile?'100%':'1050px',height:'600px',padding:0}}>
                    <button onClick={(()=>this.setState({show:false}))} style={{height:40,backgroundColor:'#efefef',border:0,alignSelf:'flex-end'}}><p style={{color:'#1B972B',fontSize:30,fontWeight:'bold'}}>x</p></button>
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <button onClick={()=>this.decTab()}  style={{flex:this.props.isMobile?0.01:0.02,display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#efefef',border:0,boxShadow: "3px 3px 3px #efefef"}}>
                            <img src={require('../imgs/nextIcon@2x.png')} style={{width:this.props.isMobile?10:15,height:this.props.isMobile?15:20,transform:this.state.lang=='ar'?'rotate(0deg)': 'rotate(180deg)'}}/>
                        </button>
                        <div style={{flex:this.props.isMobile?0.98:0.96,marginTop:'20px',height:'550px',overflow:'scroll',display:this.props.isMobile?'block':'flex'}}>
                            <div className={'col-xl-5 col-lg-5 col-sm-12 col-12'}>
                                <img src={this.getModalImg()} style={{
                                    width: '100%',
                                    margin: "auto",
                                    display: 'block',
                                    height: '300px',
                                    objectFit: 'cover'
                                }}/>
                            </div>
                            <div className={'col-xl-7 col-lg-7 col-sm-12 col-12'}>
                                <div style={{textAlign: 'center', width: '100%'}} className='ql-editor'
                                     dangerouslySetInnerHTML={{__html: this.getModalText()}}></div>
                            </div>
                            <br/>

                        </div>
                        <button onClick={()=>this.incTab()} style={{flex:this.props.isMobile?0.01:0.02,display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#efefef',border:0,boxShadow: "3px 3px 3px #efefef"}}>
                            <img src={require('../imgs/nextIcon@2x.png')} style={{width:this.props.isMobile?10:15,height:this.props.isMobile?15:20,transform:this.state.lang=='ar'?'rotate(180deg)': 'rotate(0deg)'}}/>
                        </button>
                    </div>
                </div>
                <div style={{display:this.state.showArticls?'flex':'none',flexDirection:'column',width:this.props.isMobile?'100%':'1050px',height:'600px'}}>
                    <button onClick={(()=>this.setState({showArticls:false}))} style={{height:40,backgroundColor:'#efefef',border:0,alignSelf:'flex-end'}}><p style={{color:'#1B972B',fontSize:30,fontWeight:'bold'}}>x</p></button>
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <button onClick={()=>this.setState({tabToShow:this.state.data[this.state.data.length-1],tabIndex:this.state.data.length-1,show:true,showArticls:false})}  style={{flex:this.props.isMobile?0.01:0.02,display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#efefef',border:0,boxShadow: "3px 3px 3px #efefef"}}>
                            <img src={require('../imgs/nextIcon@2x.png')} style={{width:this.props.isMobile?10:15,height:this.props.isMobile?15:20,transform:this.state.lang=='ar'?'rotate(0deg)': 'rotate(180deg)'}}/>
                        </button>
                        <div style={{flex:this.props.isMobile?0.98:0.96,marginTop:'20px',height:'550px',overflow:'scroll',display:'flex'}}>
                            {this.getSections()}
                        </div>
                        <button onClick={()=>this.setState({tabToShow:this.state.data[0],tabIndex:0,show:true,showArticls:false})} style={{flex:this.props.isMobile?0.01:0.02,display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#efefef',border:0,boxShadow: "3px 3px 3px #efefef"}}>
                            <img src={require('../imgs/nextIcon@2x.png')} style={{width:this.props.isMobile?10:15,height:this.props.isMobile?15:20,transform:this.state.lang=='ar'?'rotate(180deg)': 'rotate(0deg)'}}/>
                        </button>
                    </div>
                </div>
                <Modal show={this.state.showSection  } onHide={()=>{this.setState({showSection:false})}} id={'articalModal'} dir={this.state.lang=="ar"?"rtl":"ltr"} >
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {this.getSection()}
                    </Modal.Body>

                </Modal>
                {/*<Modal  show={this.state.showArticls  } onHide={()=>{this.setState({showArticls:false})}} id={'articalModal'} dir={this.state.lang=="ar"?"rtl":"ltr"} >
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{width:'100%'}}>

                        {this.getSections()}
                    </Modal.Body>

                </Modal>*/}
            </div>
        )
    }


}

