import React,{Component} from 'react'

import Header from "../../components/Header";

import Footer from "../../components/Footer";
import ServerController from "../../utilities/ServerController";
import Layout1 from "../../components/Layouts/Layout1";
import Layout2 from "../../components/Layouts/Layout2";
import Layout3 from "../../components/Layouts/Layout3";
import Layout4 from "../../components/Layouts/Layout4";
import SectionContainer from "../../components/SectionContainer";
import NewLayout from "../../components/Layouts/NewsLayout";
import Modal from "react-bootstrap/Modal";

export default class MissionPage extends Component {
    constructor() {
        super();
        this.state={tabToShow:null,show:false,data:null,lang:localStorage.getItem('lang'),sectionToDeleteId:null,sections:null,sectionToEdit:null,page:null,hideConfimBtn:false,success:false,show1:false,show2:false,show3:false,show4:false,showDelete:false}
        ///this.state = {sections:null,lang:localStorage.getItem('lang')};
    }

    componentDidMount() {

        let controller=new ServerController();

        controller.getSections(this.handleData,this,'Mission')
    }
    componentDidUpdate() {

    }
    handleData(data,context){
        context.setState({sections:data});
    }
    goToSectionPage(section){
        this.setState({tabToShow:section,show:true})
    }
    getSections(){
        if(this.state.sections!=null){
            let secArray=[];
            for(let i=0;i<this.state.sections.length;i+=2){
                if(this.state.sections.length%2==1 && i==this.state.sections.length-1){
                    secArray.push(
                        <div className='row' style={{
                            paddingBottom: 20,
                            direction:this.state.lang=="ar"?"rtl":"ltr"
                        }}>
                            <div className='col-5' style={{cursor:'pointer'}} onClick={()=>this.goToSectionPage(this.state.sections[i])}>
                                <NewLayout section={this.state.sections[i]}/>
                            </div>

                        </div>
                    )
                }else{
                    secArray.push(
                        <div  dir={this.state.lang=="ar"?"rtl":"ltr"} class='row' style={{borderBottomWidth:1,borderBottomStyle:'solid',borderBottomColor:'green'}}>
                            <div className='col-5' style={{cursor:'pointer'}} onClick={()=>this.goToSectionPage(this.state.sections[i])}>
                                <NewLayout section={this.state.sections[i]} />
                            </div>
                            <div className={'col-2'} >
                                <div style={{backgroundColor:'green',width:1,margin:"auto",height:300,marginTop:60}}>

                                </div>
                            </div>
                            <div className='col-5'  style={{cursor:'pointer'}} onClick={()=>this.goToSectionPage(this.state.sections[i+1])} >
                                <NewLayout section={this.state.sections[i+1]} />
                            </div>
                        </div>

                    )
                }

            }

            return(
                <div>
                    {secArray}
                </div>

            )
        }

    }
    getSection(){
        if(this.state.tabToShow!=null){


            if(this.state.tabToShow.layoutType==1){
                return (<Layout1 section={this.state.tabToShow} />)
            }
            if(this.state.tabToShow.layoutType==2){
                return (<Layout2 section={this.state.tabToShow} />)
            }
            if(this.state.tabToShow.layoutType==3){
                return (<Layout3 section={this.state.tabToShow}  />)
            }
            if(this.state.tabToShow.layoutType==4){
                return(<Layout4 section={this.state.tabToShow}  />)
            }

        }

    }
    render() {
        return (
            <div dir={this.state.lang=="ar"?"rtl":"ltr"}>
                <Header page={'news'}  context={this}></Header>
                <div id="pageContent">
                    <div id='subPageContent'>
                        {this.getSections()}
                    </div>
                    <Modal show={this.state.show  } onHide={()=>{this.setState({show:false})}} id={'tabDetailsModal'} dir={this.state.lang=="ar"?"rtl":"ltr"} >
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            {this.getSection()}
                        </Modal.Body>

                    </Modal>
                </div>

                <Footer lang={this.state.lang} />
            </div>
        )
    }
}
//
