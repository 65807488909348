import React,{Component} from 'react'

import Header from "../../components/Header";

import Footer from "../../components/Footer";
import ServerController from "../../utilities/ServerController";
import Layout1 from "../../components/Layouts/Layout1";
import Layout2 from "../../components/Layouts/Layout2";
import Layout3 from "../../components/Layouts/Layout3";
import Layout4 from "../../components/Layouts/Layout4";
import SectionContainer from "../../components/SectionContainer";
//
export default class CommunityPage extends Component {
    constructor() {
        super();
        this.state = {sections:null,lang:localStorage.getItem('lang')};
    }

    render() {
        return (
            <div dir={this.state.lang=="ar"?"rtl":"ltr"}>
                <Header page={'whatwedo'}  context={this}></Header>
                <div id="pageContent">
                    <div id='subPageContent'>
                        <SectionContainer page={'Community'}/>
                    </div>
                </div>

                <Footer lang={this.state.lang} />
            </div>
        )
    }
}
