import React,{Component} from 'react'
import '../App.css';

import testNewsImg from '../imgs/testnews.jpg'
import nextIcon from '../imgs/nextIcon@2x.png'
import backIcon from '../imgs/backIcon@2x.png'
import ServerController from "../utilities/ServerController";
import Layout1 from "../components/Layouts/Layout1";
import Layout2 from "../components/Layouts/Layout2";
import Layout3 from "../components/Layouts/Layout3";
import Layout4 from "../components/Layouts/Layout4";

export default class SectionContainer extends Component {
    constructor() {
        super();
        this.state={data:null,lang:localStorage.getItem('lang'),sectionToDeleteId:null,sections:null,sectionToEdit:null,page:null,hideConfimBtn:false,success:false,show1:false,show2:false,show3:false,show4:false,showDelete:false}
    }
    componentDidMount() {
        this.setState({lang: this.props.lang,page:this.props.page})
        let controller=new ServerController();

        controller.getSections(this.handleData,this,this.props.page)
    }
    componentDidUpdate(prevProps) {
        if(this.state.lang!=prevProps.lang){
            this.setState({lang:prevProps.lang})
        }
    }
    handleData(data,context){
        context.setState({sections:data});
    }
    getSections(){
        if(this.state.sections!=null){
            let secArray=[];
            for(let i=0;i<this.state.sections.length;i++){
                if(this.state.sections[i].layoutType==1){
                    secArray.push(<Layout1 section={this.state.sections[i]} />)
                }
                if(this.state.sections[i].layoutType==2){
                    secArray.push(<Layout2 section={this.state.sections[i]} />)
                }
                if(this.state.sections[i].layoutType==3){
                    secArray.push(<Layout3 section={this.state.sections[i]}  />)
                }
                if(this.state.sections[i].layoutType==4){
                    secArray.push(<Layout4 section={this.state.sections[i]}  />)
                }
            }

            return(
                <div>
                    {secArray}
                </div>

            )
        }

    }

    render() {
        return (
            <div dir={this.state.lang=="ar"?"rtl":"ltr"}>
                {this.getSections()}
            </div>
        )
    }


}

