import React, { Component } from 'react';
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ServerController from "../../utilities/ServerController";
import SectionContainer from '../../components/SectionContainer';
import Modal from "react-bootstrap/Modal";


export default class OurTeamPage extends Component {
    constructor() {
        super();
        this.state = {team: null,lang:localStorage.getItem('lang'),show:false,tabToShow:null};
    }

    componentDidMount() {
        let controller=new ServerController();
        controller.getOurTeam(this.handleData,this);
    }
    handleData(data,context){
        context.setState({team:data})
    }
    getTeam(){
        let items=[];
        if(this.state.team==null)
            return
        for(let i=0;i<this.state.team.length;i++){
            items.push(<div className='teamItem col-xl-3 col-lg-4  col-sm-6  col-6 ' onClick={()=>this.setState({show:true,tabToShow:this.state.team[i]})}>
                <img src={ServerController.UPLOADS+this.state.team[i].img}/>
                <h6>{this.state.lang=='ar'?this.state.team[i].nameAr:this.state.team[i].name}</h6>
                <p>{this.state.lang=='ar'?this.state.team[i].roleAr:this.state.team[i].role}</p>
            </div>)
        }
        return(<div id='tabsInner' className='row'> {items}</div>)
    }
    getModalTitle(){
        if(this.state.tabToShow==null)
            return '';
        return this.state.lang=='ar'?this.state.tabToShow.nameAr:this.state.tabToShow.name
    }
    getModalImg(){
        if(this.state.tabToShow==null)
            return '';
        return this.state.tabToShow.img;
    }
    getModalRole(){
        if(this.state.tabToShow==null)
            return '';
        return this.state.lang=='ar'?this.state.tabToShow.roleAr:this.state.tabToShow.role
    }
    getModalText(){
        if(this.state.tabToShow==null)
            return '';
        return this.state.lang=='ar'?this.state.tabToShow.textAr:this.state.tabToShow.text
    }
    render() {
        return (
            <div dir={this.state.lang=="ar"?"rtl":"ltr"}>
                <Header page={'whoweare'} context={this}></Header>
                <div id="pageContent">
                    <div id='subPageContent'>
                        <SectionContainer page={'OurTeam'}/><br/>
                        {this.getTeam()}
                        <Modal show={this.state.show  } onHide={()=>{this.setState({show:false})}} id={'tabDetailsModal'} dir={this.state.lang=="ar"?"rtl":"ltr"} >
                            <Modal.Header closeButton>
                                <Modal.Title>{this.getModalTitle()}<p style={{fontSize:'18px'}}>{this.getModalRole()}</p></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <img src={ServerController.UPLOADS+this.getModalImg()} style={{width:'90%',margin:"auto",display:'block',height:'600px',objectFit:'contain'}}/><br/>
                                <div style={{textAlign:'center',width:'auto'}} class='ql-editor' dangerouslySetInnerHTML={{__html: this.getModalText()}}></div>

                            </Modal.Body>

                        </Modal>
                    </div>
                </div>
                <div  className={'footerCover'}>

                </div>
                <Footer lang={this.state.lang}/>
            </div>
        )
    }
}
